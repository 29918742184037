/* export const columnsQuoteLines = [
	'ZONES',
	'ZONE LABEL',
	'TYPE',
	'DESCRIPTION',
	'QUANTITY',
	'TOTAL DAYS',
	'ERECT & DISMANTLE (P/U)',
	'WEEKLY HIRE FEE (%)',
	'WEEKLY HIRE FEE (P/U)',
	'TOTAL',
] */

export interface IColumnsQuoteLines {
	title: string
	dataIndex: string
	key: string
	hidden: boolean
	enabled: boolean
}

export const columnsQuoteStairVoidLines: IColumnsQuoteLines[] = [
	{
		title: 'CATEGORY',
		dataIndex: 'category',
		key: 'category',
		hidden: false,
		enabled: true,
	},
	{
		title: 'WIDTH (mm)',
		dataIndex: 'width',
		key: 'width',
		hidden: false,
		enabled: true,
	},
	{
		title: 'LENGTH (mm)',
		dataIndex: 'length',
		key: 'length',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE CODE',
		dataIndex: 'price_code',
		key: 'price_code',
		hidden: false,
		enabled: false,
	},
	{
		title: 'PRICE',
		dataIndex: 'price',
		key: 'price',
		hidden: false,
		enabled: true,
	},
	{
		title: 'ADDITIONAL TRAVEL',
		dataIndex: 'additional_travel',
		key: 'additional_travel',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE INCLUDING 16 WEEKS',
		dataIndex: 'price_16_weeks',
		key: 'price_16_weeks',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PER WEEK',
		dataIndex: 'hire_fee',
		key: 'hire_fee',
		hidden: false,
		enabled: true,
	},
]

export const columnsQuoteMobileScaffoldingLines: IColumnsQuoteLines[] = [
	{
		title: 'CATEGORY',
		dataIndex: 'category',
		key: 'category',
		hidden: false,
		enabled: true,
	},
	{
		title: 'WIDTH (mm)',
		dataIndex: 'width',
		key: 'width',
		hidden: false,
		enabled: true,
	},
	{
		title: 'LENGTH (mm)',
		dataIndex: 'length',
		key: 'length',
		hidden: false,
		enabled: true,
	},
	{
		title: 'HEIGHT (mm)',
		dataIndex: 'height',
		key: 'height',
		hidden: false,
		enabled: true,
	},
	{
		title: 'ADDITIONAL TRAVEL',
		dataIndex: 'additional_travel',
		key: 'additional_travel',
		hidden: false,
		enabled: true,
	},
	{
		title: 'HIRE',
		dataIndex: 'price',
		key: 'price',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PER WEEK',
		dataIndex: 'hire_fee',
		key: 'hire_fee',
		hidden: false,
		enabled: true,
	},
]

export const columnsQuoteEntranceVoidLines: IColumnsQuoteLines[] = [
	{
		title: 'CATEGORY',
		dataIndex: 'category',
		key: 'category',
		hidden: false,
		enabled: true,
	},
	{
		title: 'WIDTH (mm)',
		dataIndex: 'width',
		key: 'width',
		hidden: false,
		enabled: true,
	},
	{
		title: 'LENGTH (mm)',
		dataIndex: 'length',
		key: 'length',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE CODE',
		dataIndex: 'price_code',
		key: 'price_code',
		hidden: false,
		enabled: false,
	},
	{
		title: 'PRICE',
		dataIndex: 'price',
		key: 'price',
		hidden: false,
		enabled: true,
	},
	{
		title: 'ADDITIONAL TRAVEL',
		dataIndex: 'additional_travel',
		key: 'additional_travel',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE INCLUDING 16 WEEKS',
		dataIndex: 'price_16_weeks',
		key: 'price_16_weeks',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PER WEEK',
		dataIndex: 'hire_fee',
		key: 'hire_fee',
		hidden: false,
		enabled: true,
	},
]

export const columnsHangOnPlatformLines: IColumnsQuoteLines[] = [
	{
		title: 'STRATEGY',
		dataIndex: 'strategy',
		key: 'strategy',
		hidden: false,
		enabled: true,
	},
	{
		title: 'LINEAL METRES',
		dataIndex: 'lineal_metres',
		key: 'lineal_metres',
		hidden: false,
		enabled: false,
	},
	{
		title: 'CORNERS',
		dataIndex: 'corners',
		key: 'corners',
		hidden: false,
		enabled: false,
	},
	{
		title: 'PRICE/lm ',
		dataIndex: 'unit_price',
		key: 'unit_price',
		hidden: false,
		enabled: false,
	},
	{
		title: 'ADDITIONAL TRAVEL',
		dataIndex: 'additional_travel',
		key: 'additional_travel',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE INCLUDING 2 WEEKS',
		dataIndex: 'price_2_weeks',
		key: 'price_2_weeks',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PER DAY',
		dataIndex: 'hire_fee',
		key: 'hire_fee',
		hidden: false,
		enabled: true,
	},
]

export const columnsFirstFloorEdgeLines: IColumnsQuoteLines[] = [
	{
		title: 'STRATEGY',
		dataIndex: 'strategy',
		key: 'strategy',
		hidden: false,
		enabled: true,
	},
	{
		title: 'LINEAL METRES',
		dataIndex: 'lineal_metres',
		key: 'lineal_metres',
		hidden: false,
		enabled: false,
	},
	// {
	// 	title: 'CORNERS',
	// 	dataIndex: 'corners',
	// 	key: 'corners',
	// 	hidden: false,
	// 	enabled: false,
	// },
	{
		title: 'PRICE/lm ',
		dataIndex: 'unit_price',
		key: 'unit_price',
		hidden: false,
		enabled: false,
	},
	{
		title: 'ADDITIONAL TRAVEL',
		dataIndex: 'additional_travel',
		key: 'additional_travel',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE INCLUDING 2 WEEKS',
		dataIndex: 'price_2_weeks',
		key: 'price_2_weeks',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PER DAY',
		dataIndex: 'hire_fee',
		key: 'hire_fee',
		hidden: false,
		enabled: true,
	},
]

export const columnsRoofRailLines: IColumnsQuoteLines[] = [
	{
		title: 'STRATEGY',
		dataIndex: 'strategy',
		key: 'strategy',
		hidden: false,
		enabled: true,
	},
	{
		title: 'LINEAL METRES',
		dataIndex: 'lineal_metres',
		key: 'lineal_metres',
		hidden: false,
		enabled: false,
	},
	// {
	// 	title: 'CORNERS',
	// 	dataIndex: 'corners',
	// 	key: 'corners',
	// 	hidden: false,
	// 	enabled: false,
	// },
	{
		title: 'PRICE/lm ',
		dataIndex: 'unit_price',
		key: 'unit_price',
		hidden: false,
		enabled: false,
	},
	{
		title: 'ADDITIONAL TRAVEL',
		dataIndex: 'additional_travel',
		key: 'additional_travel',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE INCLUDING 2 WEEKS',
		dataIndex: 'price_2_weeks',
		key: 'price_2_weeks',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PER DAY',
		dataIndex: 'hire_fee',
		key: 'hire_fee',
		hidden: false,
		enabled: true,
	},
]

/*
	id: number | null
	strategy: number
	lineal_metres: number
	handrail_height: number
	sqm: number
	unit_price: number
	single_deck_price: number
	single_deck_price_weekly: number
	double_deck_front_elevation_price_per_sqm: number
	double_deck_front_elevation_price: number
	double_deck_front_elevation_price_weekly: number
	price_four_weeks: number
	price_per_week: number
	
	*/

export const columnsSingleDeckScaffoldingLines: IColumnsQuoteLines[] = [
	{
		title: 'STRATEGY',
		dataIndex: 'strategy',
		key: 'strategy',
		hidden: false,
		enabled: true,
	},
	{
		title: 'LINEAL METRES',
		dataIndex: 'lineal_metres',
		key: 'lineal_metres',
		hidden: false,
		enabled: false,
	},
	{
		title: 'HANDRAIL HEIGHT',
		dataIndex: 'handrail_height',
		key: 'handrail_height',
		hidden: false,
		enabled: false,
	},
	{
		title: 'SINGLE DECK / sqm',
		dataIndex: 'unit_price',
		key: 'unit_price',
		hidden: false,
		enabled: false,
	},
	{
		title: 'SINGLE DECK PRICE',
		dataIndex: 'single_deck_price',
		key: 'single_deck_price',
		hidden: false,
		enabled: true,
	},
	{
		title: 'SINGLE DECK WEEKLY',
		dataIndex: 'single_deck_price_weekly',
		key: 'single_deck_price_weekly',
		hidden: false,
		enabled: true,
	},
	{
		title: 'ADDITIONAL TRAVEL',
		dataIndex: 'additional_travel',
		key: 'additional_travel',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PRICE INCLUDING 4 WEEKS',
		dataIndex: 'price_4_weeks',
		key: 'price_4_weeks',
		hidden: false,
		enabled: true,
	},
	{
		title: 'PER WEEK',
		dataIndex: 'hire_fee',
		key: 'hire_fee',
		hidden: false,
		enabled: true,
	},
]

export const columnsQuoteAdditionalLines = [
	'Type',
	'Description',
	'Duration/Quantity',
	'Fixed Charge',
	'Total Cost',
]

export const columnsSQM = ['length', 'height', 'width', 'total_dimensions']
export const columnsHours = ['total_days', 'quantity']

// Form Types

export interface IQuoteForm {
	quote_type: string
	job_type: string
	variation_job_id: number | null
	PO_Number: string | null
	max_zones: number
	client: number | null
	client_supervisor: number | null
	client_estimator?: number | null
	quote_num?: string
	scope_of_work: string
	estimator: number | null
	fullAddress: string
	street: string
	street2: string
	city: string
	postal: string
	quote_additional_lines: IQuoteAdditionalLines[]
	terms: string
	erectDismantleTotal: number
	additionalTotal: number
	weekTotal: number
	total: number
	rates: IRates[]
	zones: IQuoteZones[]
	file_1: string
	file_2: string
	file_3: string
	file_4: string
	quote_entrance_void_lines: IQuoteEntranceVoidLine[]
	quote_stair_lines: IQuoteStairVoidLine[]
	quote_mobile_lines: IQuoteMobileLine[]
	quote_first_floor_edge_lines: IQuoteFirstFloorEdgeLine[]
	quote_hang_on_platform_lines: IQuoteHangOnPlatformLine[]
	quote_roof_rail_lines: IQuoteRoofRailLine[]
	quote_single_deck_lines: IQuoteSingleDeckScaffoldLine[]
	table_options: any
	main_origin: string
}
export interface DTOQuoteEntranceLine {
	id?: number | null
	quote_id?: number
	description: string
	width: number
	length: number
	additional_travel: number
	price_16_weeks: number
	hire_fee: number
	override_price: boolean | null
}
export interface DTOQuoteFirstFloorEdgeLine {
	id?: number | null
	quote_id?: number
	description: string
	strategy: number
	lineal_metres: number
	additional_travel: number
	price_2_weeks: number
	hire_fee: number
	override_price: boolean | null
}
export interface DTOQuoteHangOnPlatformLine {
	id?: number | null
	quote_id?: number
	description: string
	strategy: number
	lineal_metres: number
	corners: number
	additional_travel: number
	price_2_weeks: number
	hire_fee: number
	override_price: boolean | null
}
export interface DTOQuoteMobileLine {
	id?: number | null
	quote_id?: number
	description: string
	length: number
	width: number
	height: number
	additional_travel: number
	price: number
	hire_fee: number
	override_price: boolean | null
}
export interface DTOQuoteRoofRailLine {
	id?: number | null
	quote_id?: number
	description: string
	strategy: number
	lineal_metres: number
	additional_travel: number
	price_2_weeks: number
	hire_fee: number
	override_price: boolean | null
}
export interface DTOQuoteSingleDeckLine {
	id?: number | null
	quote_id?: number
	description: string
	strategy: number
	lineal_metres: number
	handrail_height: number
	additional_travel: number
	price_4_weeks: number
	hire_fee: number
	override_price: boolean | null
}
export interface DTOQuoteStairLine {
	id?: number | null
	quote_id?: number
	description: string
	width: number
	length: number
	additional_travel: number
	price_16_weeks: number
	hire_fee: number
	override_price: boolean | null
}
export interface CreateQuoteDTO {
	quote_type: string
	PO_Number: string
	variation_job_id: number | null
	main_origin: string
	job_type: string
	client: number | null
	client_supervisor: number | null
	client_estimator?: number | null
	scope_of_work: string
	estimator: number | null
	fullAddress: string
	file_1: string
	file_2: string
	file_3: string
	file_4: string
	table_options: string
	street: string
	street2: string
	city: string
	postal: string
	longitude: number
	latitude: number
	quote_lines_entrance: DTOQuoteEntranceLine[]
	quote_lines_first_floor_edge: DTOQuoteFirstFloorEdgeLine[]
	quote_lines_hang_on_platform: DTOQuoteHangOnPlatformLine[]
	quote_lines_mobile: DTOQuoteMobileLine[]
	quote_lines_roof_rail: DTOQuoteRoofRailLine[]
	quote_lines_single_deck: DTOQuoteSingleDeckLine[]
	quote_lines_stair: DTOQuoteStairLine[]
	quote_additional_lines: any
	terms: string
	erectDismantleTotal: number
	additionalTotal: number
	weekTotal: number
	total: number
	rates: any
	zones: any
}

export interface QuoteData {
	job_type: string
	id: number
	quote_num: string
	street: string
	street2: string
	city: string
	status: string
	created_at: string
	updated_at: string
	max_zones: number
	scope_of_work: string
	postal: string
	weekTotal: number
	total: number
	terms: string
	client_supervisor: null | number
	client_estimator?: null | number
	estimator: number
	approved_by: null | number
	variation_job_id: null | number
	quote_type: string
	clientType: null | string
	PO_Number: null | string
	additionalTotal: number
	erectDismantleTotal: number
	emailStatus: string
	longitude: number
	latitude: number
	fullAddress: string
	client: number
	createdAt: string
	updatedAt: string
	file_1: string
	file_2: string
	file_3: string
	file_4: string
	table_options: string[]
	main_origin: string
	clientData: {
		id: number
		client_name: string
		phone: string
		email: string
	}
	clientSupervisorData: {
		id: number
		name: string
		phone: string
		email: string
	}
	clientEstimatorData: {
		id: number
		name: string
		phone: string
		email: string
	}
	estimatorData: {
		id: number
		staff_name: string
		email: string
		mobile: string
	}
}

export interface QuoteDataToUpdate {
	job_type: string
	street: string
	street2: string
	city: string
	max_zones: number
	scope_of_work: string
	postal: string
	weekTotal: number
	total: number
	terms: string
	client_supervisor: number | null
	client_estimator?: number | null
	estimator: number
	variation_job_id: null | number
	quote_type: string
	PO_Number: null | string
	additionalTotal: number
	erectDismantleTotal: number
	fullAddress: string
	client: number
	file_1: string
	file_2: string
	file_3: string
	file_4: string
	table_options: string[]
	main_origin: string
}

// Types

export interface IQuoteEntranceVoidLine {
	id?: number | null
	category: string
	width: number
	length: number
	price_code: string
	price: number
	additional_travel: number
	price_16_weeks: number
	hire_fee: number
	override_price: boolean
}

export interface IQuoteStairVoidLine {
	id: number | null
	category: string
	width: number
	length: number
	price_code: string
	price: number // for the ui price
	additional_travel: number
	price_16_weeks: number
	hire_fee: number
	override_price: boolean
}
export interface IQuoteHangOnPlatformLine {
	id: number | null
	strategy: number
	lineal_metres: number
	corners: number
	unit_price: number
	additional_travel: number
	price_2_weeks: number
	hire_fee: number
	override_price: boolean
}

export interface IQuoteFirstFloorEdgeLine {
	id: number | null
	strategy: number
	lineal_metres: number
	unit_price: number
	additional_travel: number
	price_2_weeks: number
	hire_fee: number
	override_price: boolean
}

export interface IQuoteRoofRailLine {
	id: number | null
	strategy: number
	lineal_metres: number
	unit_price: number
	additional_travel: number
	price_2_weeks: number
	hire_fee: number
	override_price: boolean
}

export interface IQuoteSingleDeckScaffoldLine {
	id: number | null
	strategy: number
	lineal_metres: number
	handrail_height: number
	unit_price: number
	single_deck_price: number
	single_deck_price_weekly: number
	additional_travel: number
	sqm: number
	price_4_weeks: number
	hire_fee: number
	override_price: boolean
}
export interface IQuoteMobileLine {
	id: number | null
	category: string
	length: number
	width: number
	height: number
	additional_travel: number
	price: number
	hire_fee: number
	override_price: boolean
}

export interface IQuoteAdditionalLines {
	id: string | number | null
	type: string
	description: string
	duration_quantity: number
	fixed_charge: number
	total_cost: number
	override_price: boolean
}

export interface IRates {
	id: number | null
	type: string
	service: string
	fee: number
}

export interface IQuoteZones {
	id: string | number | null
	zone_id: string | number
	zone_label: string
	quote_id?: string
}

// Handlers

export interface IZonesHandlers {
	addNewZone: (zone_id: number) => void
	removeZone: (index: number) => void
	updateZone: (index: number, field: string, value: string | number) => void
}

export interface IQuoteLineHandlers {
	addNewQuoteLine: () => void
	removeQuoteLine: (index: number) => void
	updateQuoteLine: (
		index: number,
		field: string,
		value: string | number | boolean
	) => void
}

export interface IQuoteAdditionalLinesHandlers {
	addNewQuoteAdditionalLine: () => void
	removeQuoteAdditionalLine: (index: number) => void
	updateQuoteAdditionalLine: (
		index: number,
		field: string,
		value: string | number
	) => void
}

export interface IRatesHandlers {
	addNewRate: () => void
	removeRate: (index: number) => void
	updateRate: (index: number, field: string, value: string | number) => void
}
