import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { CrewsServices, DataTableServices } from 'services'
import { Container, DataTableHeader, PageHeading } from 'common'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { CreateCrewsForm } from 'components/Crews'
import { AppRoutes } from 'config'
import { PencilIcon } from '@heroicons/react/24/solid'
import moment from 'moment'

export const CrewsMainTable = () => {
	const location = useLocation()
	const { data, isLoading } = CrewsServices.useCrews()
	const [openClientForm, setOpenClientForm] = useState(false)

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: ['id'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	console.log(data)

	return (
		<div className="card">
			<PageHeading
				title="Crews"
				createBtn="Create Crew"
				isEditable={false}
				setOpen={setOpenClientForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					emptyMessage="No crews found.">
					<Column header="Crew Leader" field="crew_leader.staff_name" style={{ minWidth: '10rem' }} />
					<Column header="Vehicle" field="vehicle.CodeName" style={{ minWidth: '10rem' }} />
					<Column header="Staff List" field="staff_members" style={{ minWidth: '10rem' }} body={
						(row: any) => {
							return (
								<div>
									{row.staff_members.map((staff: any) => (
										<div key={staff.id}>
											{staff.staff_name}
										</div>
									))}
								</div>
							)
						}
					} />
					<Column
						header="Edit"
						bodyClassName="p-text-center"
						style={{ width: '3rem' }}
						body={(row) => (
							<Link
								to={{
									pathname: AppRoutes.privateRoutes.CrewsEdit.replace(
										':id',
										row.id
									),
								}}
								state={{ background: location, name: 'editCrew' }}>
								<PencilIcon className="text-gray-600 h-4 w-4" />
							</Link>
						)}
					/>
				</DataTable>
			</Container>
			<CreateCrewsForm
				formType="create"
				heading="Create Crew"
				open={openClientForm}
				setOpen={setOpenClientForm}
			/>
		</div>
	)
}
