import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import {
	IQuoteAdditionalLines,
	IQuoteEntranceVoidLine,
	IQuoteFirstFloorEdgeLine,
	IQuoteHangOnPlatformLine,
	IQuoteMobileLine,
	IQuoteRoofRailLine,
	IQuoteSingleDeckScaffoldLine,
	IQuoteStairVoidLine,
	IQuoteZones,
	IRates,
	QuoteDataToUpdate,
} from 'models'

export const useUpdateQuote = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateQuote = async (quote: QuoteDataToUpdate, id: number) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.updateQuote,
				quote,
				id
			)
			showSuccess('Quote updated successfully')
			queryClient.refetchQueries(['quotes'])
			queryClient.refetchQueries(['quote', id])
			return response
		} catch (error) {
			showError('Something went wrong updating Quote')
			throw new Error('Something went wrong updating Quote')
		}
	}

	const updateQuoteLines = async (
		quote_lines: {
			quote_entrance_void_lines: IQuoteEntranceVoidLine[]
			quote_stair_lines: IQuoteStairVoidLine[]
			quote_hang_on_platform_lines: IQuoteHangOnPlatformLine[]
			quote_first_floor_edge_lines: IQuoteFirstFloorEdgeLine[]
			quote_roof_rail_lines: IQuoteRoofRailLine[]
			quote_single_deck_lines: IQuoteSingleDeckScaffoldLine[]
			quote_mobile_lines: IQuoteMobileLine[]
		},
		id: number
	) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_entrance,
				quote_lines.quote_entrance_void_lines,
				id
			)
			queryClient.refetchQueries(['quote_entrance_lines', id])

			await postRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_stair,
				quote_lines.quote_stair_lines,
				id
			)
			queryClient.refetchQueries(['quote_stair_lines', id])

			await postRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_hang_on_platform,
				quote_lines.quote_hang_on_platform_lines,
				id
			)
			queryClient.refetchQueries(['quote_hang_on_platform_lines', id])

			await postRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_first_floor_edge,
				quote_lines.quote_first_floor_edge_lines,
				id
			)
			queryClient.refetchQueries(['quote_first_floor_edge_lines', id])

			await postRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_roof_rail,
				quote_lines.quote_roof_rail_lines,
				id
			)
			queryClient.refetchQueries(['quote_roof_rail_lines', id])

			await postRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_single_deck,
				quote_lines.quote_single_deck_lines,
				id
			)
			queryClient.refetchQueries(['quote_single_deck_lines', id])

			await postRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_mobile,
				quote_lines.quote_mobile_lines,
				id
			)
			queryClient.refetchQueries(['quote_mobile_lines', id])

			showSuccess('Quote lines updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating Quote Lines')
			throw new Error('Something went wrong updating Quote Lines')
		}
	}

	const updateQuoteAddOns = async (
		quote_addons: IQuoteAdditionalLines[],
		id: number
	) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.quote_addons,
				quote_addons,
				id
			)
			showSuccess('Quote Additional Items updated successfully')
			queryClient.refetchQueries(['quote_addons', id])
			return response
		} catch (error) {
			showError('Something went wrong updating Quote Additional Items')
			throw new Error('Something went wrong updating Quote Additional Items')
		}
	}

	const updateQuoteZones = async (quote_zones: IQuoteZones[], id: number) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.quote_zones,
				quote_zones,
				id
			)
			showSuccess('Quote Zones updated successfully')
			queryClient.refetchQueries(['quote_zones', id])
			return response
		} catch (error) {
			showError('Something went wrong updating Quote Zones')
			throw new Error('Something went wrong updating Quote Zones')
		}
	}

	const updateQuoteRates = async (quote_rates: IRates[], id: number) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.quote_rates,
				quote_rates,
				id
			)
			showSuccess('Quote Rates updated successfully')
			queryClient.refetchQueries(['quote_rates', id])
			return response
		} catch (error) {
			showError('Something went wrong updating Quote Rates')
			throw new Error('Something went wrong updating Quote Rates')
		}
	}

	return {
		updateQuote,
		updateQuoteLines,
		updateQuoteAddOns,
		updateQuoteZones,
		updateQuoteRates,
	}
}
