import { PageHeading } from 'common'
import { StaffScheduler } from 'components'
import { jobTaskColors } from 'models'

export const StaffSchedulerMain = () => {
	return (
		<div className="px-6 py-6">
			<PageHeading title="Staff Scheduler" isEditable={false}>
			<div className="content-end flex w-full justify-end mb-6">
					<div className="bg-slate-200 rounded-md w-fit border border-slate-400">
						<p className="text-center font-bold">Legend</p>
						<ul
							className="mx-2"
							style={{
								listStyleType: 'none',
								padding: 0,
								display: 'flex',
								flexDirection: 'row',
								gap: '10px',
							}}>
							{Object.entries(jobTaskColors).map(([label, code]) => (
								<li
									key={label}
									style={{
										backgroundColor: code,
										borderRadius: '5px',
										color: '#FFFFFF',
										padding: '5px',
										marginTop: '5px',
										marginBottom: '5px',
									}}>
									{label}
								</li>
							))}
						</ul>
					</div>
				</div>
			</PageHeading>
			<StaffScheduler.Scheduler />
		</div>
	)
}
