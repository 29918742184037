import { IQuoteStairVoidLine, IQuoteZones, IRates } from 'models'
import * as quoteHelpers from './quoteLineHelpersGeneric'

const initLine: IQuoteStairVoidLine = {
	id: null,
	category: 'Stair Void',
	price_code: '',
	price: 0,
	width: 0,
	length: 0,
	additional_travel: 0,
	price_16_weeks: 0,
	hire_fee: 0,
	override_price: false,
}

const addNewQuoteLine = (
	quote_lines: IQuoteStairVoidLine[]
): IQuoteStairVoidLine[] => {
	quote_lines.push(initLine)
	return quote_lines
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteStairVoidLine[]
): IQuoteStairVoidLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteStairVoidLine[],
	field: string,
	value: string | number | boolean
): IQuoteStairVoidLine[] => {
	const newQuoteLines: IQuoteStairVoidLine[] = quote_lines.map((line, i) => {
		if (i === index) {
			const newLine = {
				...line,
				[field]: value,
			}
			return newLine
		}
		return line
	})

	return newQuoteLines
}

const calculateQuoteLines = (
	distance_gpo: string | null,
	quote_lines: IQuoteStairVoidLine[]
): IQuoteStairVoidLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		// console.log(line)
		let priceCode = ''
		let additionalTravel = 0
		const travelCost = Math.max(
			0,
			Number(((Number(distance_gpo) * 4 - 200) * 1.5)?.toFixed(2))
		)
		let fullPrice = 0
		let weeklyPrice = 0
		if (line.width && line.length) {
			const calculatedPriceCode = quoteHelpers.convertToVoidCategory(
				line.width,
				line.length
			)
			priceCode = quoteHelpers.findPriceCode(calculatedPriceCode)
			const prices = quoteHelpers.getPriceForCode(priceCode)
			fullPrice = prices?.initial || 0
			weeklyPrice = prices?.weekly || 0
		} else {
			priceCode = '...'
		}
		if (distance_gpo) {
			// console.log(distance_gpo)
			if (Number(distance_gpo) > 50) {
				additionalTravel = travelCost
			}
		}
		const calculatedLine: IQuoteStairVoidLine = {
			...line,
			price_16_weeks: line.override_price == false ? fullPrice : line.price_16_weeks,
			price_code: priceCode,
			additional_travel: additionalTravel,
			hire_fee: weeklyPrice,
			price: fullPrice,
		}
		return calculatedLine
	})
	return newQuoteLines
}

export const quoteStairVoidLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateQuoteLines,
}
