import { Text, View } from '@react-pdf/renderer'
import { RowGroup } from './Group'
import { QuoteLineRow as styles } from './styles'
import { numberFormat } from 'utilities'
import React from 'react'

type Item = any

type GroupedItems = {
	[key: string]: Item[]
}

type RowProps = {
	items: any[]
	groupable?: true // groupable siempre es true en esta versión
}

export const QuoteLinesRows = ({ items }: RowProps) => {
	const groupBy = function group(data: Item[], key: keyof Item) {
		return data.reduce((storage: GroupedItems, item: Item) => {
			const group = item[key]
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			storage[group] = storage[group] || []
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			storage[group].push(item)
			return storage
		}, {})
	}

	const grouped: GroupedItems = groupBy(items, 'strategy')

	const sortedJson: GroupedItems = Object.keys(grouped)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		.sort((a, b) => grouped[a][0].strategy - grouped[b][0].strategy)
		.reduce((accu: GroupedItems, val: string) => {
			accu[`${val}`] = grouped[val]
			return accu
		}, {})

	const rows: JSX.Element[] = Object.entries(sortedJson).map(
		(item, mainIndex) => {
			// console.log(item[0])
			return (
				<React.Fragment key={mainIndex}>
					<RowGroup text={item[0]} />
					{item[1].map((row, index) => {
						// console.log(row)
						return (
							<React.Fragment key={index}>
								<View style={styles.row}>
									<Text style={styles.strategy}>{row.quantity}</Text>
									<Text style={styles.total}>
										{`${numberFormat.format(Number(row.total))}`}
									</Text>
									<Text style={styles.fee}></Text>
								</View>
								<View style={styles.row}>
									<Text style={styles.strategy}>{row.hire_description}</Text>
									<Text style={styles.total}></Text>
									<Text style={styles.fee}>
										{`${numberFormat.format(Number(row.fee))} / ${
											[
												'Entrance Void Protection',
												'Stair Void Protection',
												'Mobile Scaffolding',
												'Single Deck Scaffolding',
											].includes(row.description!)
												? 'week'
												: 'day'
										}`}
									</Text>
								</View>
							</React.Fragment>
						)
					})}
				</React.Fragment>
			)
		}
	)

	return <>{rows}</>
}
