export const visitTypeOptions = [
	{
		value: 'Install',
		label: 'Install',
	},
	{
		value: 'Dismantle',
		label: 'Dismantle',
	},
	{
		value: 'Pull Out - Leave on Site',
		label: 'Pull Out - Leave on Site',
	},
	{
		value: 'Remove Adjustment',
		label: 'Remove Adjustment',
	},
	{
		value: 'Adjustment',
		label: 'Adjustment',
	},
	{
		value: 'Reinstall',
		label: 'Reinstall',
	},
	{
		value: 'Remedial',
		label: 'Remedial',
	},
]

export const visitsStatusOptions = [
	{ label: 'Pending Prestart', value: 'Pending Prestart' },
	{ label: 'Pending Close of Visit', value: 'Pending Close of Visit' },
	{ label: 'Completed', value: 'Completed' },
]
