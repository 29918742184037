import {
	DTOQuoteEntranceLine,
	DTOQuoteFirstFloorEdgeLine,
	DTOQuoteHangOnPlatformLine,
	DTOQuoteMobileLine,
	DTOQuoteRoofRailLine,
	DTOQuoteSingleDeckLine,
	DTOQuoteStairLine,
	IQuoteEntranceVoidLine,
	IQuoteFirstFloorEdgeLine,
	IQuoteForm,
	IQuoteHangOnPlatformLine,
	IQuoteMobileLine,
	IQuoteRoofRailLine,
	IQuoteSingleDeckScaffoldLine,
	IQuoteStairVoidLine,
} from 'models/quotes.model'
import {
	QuoteData,
	IQuoteAdditionalLines,
	IQuoteZones,
	IRates,
} from 'models/quotes.model'

interface IAssignDataToEdit {
	quote_data: QuoteData
	quote_entrance_lines: DTOQuoteEntranceLine[]
	quote_stair_lines: DTOQuoteStairLine[]
	quote_hang_on_platform_lines: DTOQuoteHangOnPlatformLine[]
	quote_first_floor_edge_lines: DTOQuoteFirstFloorEdgeLine[]
	quote_roof_rail_lines: DTOQuoteRoofRailLine[]
	quote_single_deck_lines: DTOQuoteSingleDeckLine[]
	quote_mobile_lines: DTOQuoteMobileLine[]
	quote_addons: IQuoteAdditionalLines[]
	quote_rates: IRates[]
	quote_zones: IQuoteZones[]
}

export const assignDataToEdit = ({
	quote_data,
	quote_entrance_lines,
	quote_stair_lines,
	quote_hang_on_platform_lines,
	quote_first_floor_edge_lines,
	quote_roof_rail_lines,
	quote_single_deck_lines,
	quote_mobile_lines,
	quote_addons,
	quote_rates,
	quote_zones,
}: IAssignDataToEdit): IQuoteForm => {
	const loaded_entrance_lines: IQuoteEntranceVoidLine[] =
		quote_entrance_lines?.map((line) => ({
			category: 'Entrance Void',
			price_code: '',
			price: 0,
			id: line.id || null,
			width: line.width,
			length: line.length,
			additional_travel: line.additional_travel,
			price_16_weeks: line.price_16_weeks,
			hire_fee: line.hire_fee,
			override_price: line.override_price || false,
		}))

	const loaded_stair_lines: IQuoteStairVoidLine[] = quote_stair_lines.map(
		(line) => ({
			category: 'Stair Void',
			price_code: '',
			price: 0,
			id: line.id || null,
			width: line.width,
			length: line.length,
			additional_travel: line.additional_travel,
			price_16_weeks: line.price_16_weeks,
			hire_fee: line.hire_fee,
			override_price: line.override_price || false,
		})
	)
	
	const loaded_hang_on_platform_lines: IQuoteHangOnPlatformLine[] =
		quote_hang_on_platform_lines.map((line) => ({
			id: line.id || null,
			strategy: line.strategy,
			lineal_metres: line.lineal_metres,
			corners: line.corners,
			unit_price: -1,
			additional_travel: line.additional_travel,
			price_2_weeks: line.price_2_weeks,
			hire_fee: line.hire_fee,
			override_price: line.override_price || false,
		}))

	const newValues: IQuoteForm = {
		quote_type: quote_data?.quote_type,
		job_type: quote_data?.job_type,
		variation_job_id: quote_data?.variation_job_id,
		PO_Number: quote_data?.PO_Number || null,
		max_zones: quote_data?.max_zones,
		client: quote_data?.client,
		client_supervisor: quote_data?.client_supervisor || null,
		client_estimator: quote_data?.client_estimator || null,
		quote_num: quote_data?.quote_num,
		scope_of_work: quote_data?.scope_of_work,
		estimator: quote_data?.estimator,

		fullAddress: quote_data?.fullAddress,
		street: quote_data?.street,
		street2: quote_data?.street2,
		city: quote_data?.city,
		postal: quote_data?.postal,

		quote_entrance_void_lines: loaded_entrance_lines,
		quote_stair_lines: loaded_stair_lines,
		quote_hang_on_platform_lines: loaded_hang_on_platform_lines,
		quote_first_floor_edge_lines:
			quote_first_floor_edge_lines as unknown as IQuoteFirstFloorEdgeLine[],
		quote_roof_rail_lines:
			quote_roof_rail_lines as unknown as IQuoteRoofRailLine[],
		quote_single_deck_lines:
			quote_single_deck_lines as unknown as IQuoteSingleDeckScaffoldLine[],
		quote_mobile_lines: quote_mobile_lines as unknown as IQuoteMobileLine[],

		quote_additional_lines: quote_addons,

		terms: quote_data?.terms,

		erectDismantleTotal: quote_data?.erectDismantleTotal,
		additionalTotal: quote_data?.additionalTotal,
		weekTotal: quote_data?.weekTotal,
		total: quote_data?.total,

		rates: quote_rates,
		zones: quote_zones,

		file_1: quote_data?.file_1,
		file_2: quote_data?.file_2,
		file_3: quote_data?.file_3,
		file_4: quote_data?.file_4,
		table_options: quote_data?.table_options,
		main_origin: quote_data?.main_origin,
	}
	return newValues
}
