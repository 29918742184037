import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { quoteAdditionalLinesFn } from './addonsHandlers'
import { ratesFn } from './ratesHandlers'
import { zonesFn } from './zonesHandlers'
import { useUpdateQuote } from './updateQuote'
import {
	IQuoteForm,
	IQuoteAdditionalLinesHandlers,
	IRatesHandlers,
	IZonesHandlers,
	QuoteData,
	IQuoteEntranceVoidLine,
	IQuoteMobileLine,
	IQuoteStairVoidLine,
	IQuoteAdditionalLines,
	IQuoteZones,
	IRates,
	QuoteDataToUpdate,
	IColumnsQuoteLines,
	columnsHours,
	columnsSQM,
	IQuoteLineHandlers,
	IQuoteHangOnPlatformLine,
	IQuoteFirstFloorEdgeLine,
	IQuoteRoofRailLine,
	IQuoteSingleDeckScaffoldLine,
	CreateQuoteDTO,
	DTOQuoteEntranceLine,
	DTOQuoteStairLine,
	DTOQuoteHangOnPlatformLine,
	DTOQuoteFirstFloorEdgeLine,
	DTOQuoteRoofRailLine,
	DTOQuoteSingleDeckLine,
	DTOQuoteMobileLine,
} from 'models/quotes.model'
import { useServiceRates } from './useServiceRates'
import { totalsFn } from './totalHandlers'
import { useCreateQuote } from './createQuote'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'config'
import { assignDataToEdit } from './assignDataToEdit'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { Roles } from 'models'
import { quoteEntranceVoidLinesFn } from './linesHandlers/quoteEntranceVoidLinesHandlers'
import { quoteStairVoidLinesFn } from './linesHandlers/quoteStairVoidLinesHandlers'
import { quoteMobileScaffoldingLinesFn } from './linesHandlers/quoteMobileScaffoldingLinesHandlers'
import { quoteFirstFloorEdgeLinesFn } from './linesHandlers/quoteFirstFloorEdgeHandlers'
import { quoteRoofRailLinesFn } from './linesHandlers/quoteRoofRailHandlers'
import { quoteHangOnPlatformLinesFn } from './linesHandlers/quoteHangOnPlatformHandler'
import { quoteSingleDeckLinesFn } from './linesHandlers/quoteSingleDeckLinesHandlers'

interface IUseFormHandler {
	quote_id?: number
	quote_data?: QuoteData
	quote_entrance_lines?: DTOQuoteEntranceLine[]
	quote_stair_lines?: DTOQuoteStairLine[]
	quote_hang_on_platform_lines?: DTOQuoteHangOnPlatformLine[]
	quote_first_floor_edge_lines?: DTOQuoteFirstFloorEdgeLine[]
	quote_roof_rail_lines?: DTOQuoteRoofRailLine[]
	quote_single_deck_lines?: DTOQuoteSingleDeckLine[]
	quote_mobile_lines?: DTOQuoteMobileLine[]
	quote_addons?: IQuoteAdditionalLines[]
	quote_rates?: IRates[]
	quote_zones?: IQuoteZones[]
}

const itemsTabsAdmin = [
	// { label: 'Zones', id: 0 },
	{ label: 'Rates', id: 1 },
	{ label: 'Admin Rates', id: 2 },
]

const itemsTabsStandard = [
	// { label: 'Zones', id: 0 },
	{ label: 'Rates', id: 1 },
]

export const useFormHandler = ({
	quote_id,
	quote_data,
	quote_entrance_lines,
	quote_stair_lines,
	quote_hang_on_platform_lines,
	quote_first_floor_edge_lines,
	quote_roof_rail_lines,
	quote_single_deck_lines,
	quote_mobile_lines,
	quote_addons,
	quote_rates,
	quote_zones,
}: IUseFormHandler) => {
	const loggedInUserData = useSelector((store: AppStore) => store.user)

	const itemsTabs =
		loggedInUserData?.userType === Roles.admin
			? itemsTabsAdmin
			: itemsTabsStandard

	const navigate = useNavigate()
	const { createQuote } = useCreateQuote()
	const {
		updateQuote,
		updateQuoteAddOns,
		updateQuoteLines,
		updateQuoteRates,
		updateQuoteZones,
	} = useUpdateQuote()

	const [editInfoLoaded, setEditInfoLoaded] = useState(false)
	const [backToMainTable, setBackToMainTable] = useState(false)

	const ratesInfo = useServiceRates()

	const validationSchema = Yup.object().shape({
		estimator: Yup.string().required('Estimator is Required'),
		client: Yup.string().required('Client is Required'),
		client_supervisor: Yup.string().required('Client Supervisor is Required'),
		/* 		variation_job_id: Yup.string().when('quote_type', (quote_type) => {
			if (quote_type[0] === 'Variation') {
				return Yup.string().required('Variation Job ID is required')
			}
			return Yup.string()
		}),
		zones: Yup.array().of(
			Yup.object().shape({
				zone_label: Yup.string().required('Zone is required'),
			})
		), */
	})

	const loaded_entrance_lines: IQuoteEntranceVoidLine[] | undefined = quote_entrance_lines?.map((line) => ({
		category: 'Entrance Void',
		price_code: '',
		price: 0,
		id: line.id || null,
		width: line.width,
		length: line.length,
		additional_travel: line.additional_travel,
		price_16_weeks: line.price_16_weeks,
		hire_fee: line.hire_fee,
		override_price: line.override_price || false,
	}))
	const loaded_stair_lines: IQuoteStairVoidLine[] | undefined = quote_stair_lines?.map((line) => ({
		category: 'Stair Void',
		price_code: '',
		price: 0,
		id: line.id || null,
		width: line.width,
		length: line.length,
		additional_travel: line.additional_travel,
		price_16_weeks: line.price_16_weeks,
		hire_fee: line.hire_fee,
		override_price: line.override_price || false,
	}))

	const loaded_mobile_lines: IQuoteMobileLine[] | undefined = quote_mobile_lines?.map((line) => ({
		id: line.id || null,
		category: 'Mobile Scaffolding',
		length: line.length,
		width: line.width,
		height: line.height,
		additional_travel: line.additional_travel,
		price: line.price,
		hire_fee: line.hire_fee,
		override_price: line.override_price || false,
	}))

	const loaded_first_floor_edge_lines: IQuoteFirstFloorEdgeLine[] | undefined = quote_first_floor_edge_lines?.map(
		(line) => ({
			id: line.id || null,
			category: 'First Floor Edge Protection',
			strategy: line.strategy,
			lineal_metres: line.lineal_metres,
			additional_travel: line.additional_travel,
			unit_price: 0,
			price_2_weeks: line.price_2_weeks,
			hire_fee: line.hire_fee,
			override_price: line.override_price || false,
		})
	)

	const loaded_hang_on_platform_lines: IQuoteHangOnPlatformLine[] | undefined = quote_hang_on_platform_lines?.map(
		(line) => ({
			id: line.id || null,
			category: 'Hang On Platform',
			strategy: line.strategy,
			lineal_metres: line.lineal_metres,
			corners: line.corners,
			additional_travel: line.additional_travel,
			unit_price: 0,
			price_2_weeks: line.price_2_weeks,
			hire_fee: line.hire_fee,
			override_price: line.override_price || false,
		})
	)

	const loaded_roof_rail_lines: IQuoteRoofRailLine[] | undefined = quote_roof_rail_lines?.map((line) => ({
		id: line.id || null,
		category: 'Roof Rail',
		strategy: line.strategy,
		lineal_metres: line.lineal_metres,
		additional_travel: line.additional_travel,
		unit_price: 0,
		price_2_weeks: line.price_2_weeks,
		hire_fee: line.hire_fee,
		override_price: line.override_price || false,
	}))

	const loaded_single_deck_lines: IQuoteSingleDeckScaffoldLine[] | undefined = quote_single_deck_lines?.map(
		(line) => ({
			id: line.id || null,
			strategy: line.strategy,
			lineal_metres: line.lineal_metres,
			handrail_height: line.handrail_height,
			unit_price: 0,
			single_deck_price: 0,
			single_deck_price_weekly: 0,
			additional_travel: line.additional_travel,
			sqm: 0,
			price_4_weeks: line.price_4_weeks,
			hire_fee: line.hire_fee,
			override_price: line.override_price || false,
		})
	)

	// TODO LOAD ENTRANCE VOID LINES

	const initialValues: IQuoteForm = {
		quote_type: quote_data?.quote_type || 'New',
		job_type: quote_data?.job_type || 'Commercial',
		variation_job_id: quote_data?.variation_job_id || null,
		PO_Number: quote_data?.PO_Number || '',
		max_zones: quote_data?.max_zones || 12,
		client: quote_data?.client || null,
		client_supervisor: quote_data?.client_supervisor || null,
		client_estimator: quote_data?.client_estimator || null,
		quote_num: quote_data?.quote_num || '',
		scope_of_work: quote_data?.scope_of_work || '',
		estimator: quote_data?.estimator || null,
		fullAddress: quote_data?.fullAddress || '',
		street: quote_data?.street || '',
		street2: quote_data?.street2 || '',
		city: quote_data?.city || '',
		postal: quote_data?.postal || '',
		quote_entrance_void_lines: loaded_entrance_lines || [],
		quote_stair_lines: loaded_stair_lines || [],
		quote_mobile_lines: loaded_mobile_lines || [],
		quote_first_floor_edge_lines: loaded_first_floor_edge_lines || [],
		quote_hang_on_platform_lines: loaded_hang_on_platform_lines || [],
		quote_roof_rail_lines: loaded_roof_rail_lines || [],
		quote_single_deck_lines: loaded_single_deck_lines || [],
		table_options: quote_data?.table_options || [],

		quote_additional_lines: quote_addons || [],

		terms: quote_data?.terms || `Total price includes hire, labour, and transportation.
        
        - NOTE: A minimum of two (2) clear working days\` notice is required for erection, modifications, dismantling or any other work required on scaffolding.
        
        - Any additional work outside the initial quotation detailed above will be charged out accordingly
        
        - Weekly hire rate applies after initial quoted hire period.
        
        - In the event scaffolding is pulled down in progressive stages then continuing hire charges will reflect the amount of scaffolding remaining on site
        
        - Client is responsible for covering any exposed electrical wiring within 4.5 m of scaffolding.
        
        - The client is liable for any loss of or damage to the goods during the hiring period and must at its own cost replace or repair the goods.
        
        - Any additional inspections (including 30 day inspections) required on any scaffolding will be charged out as a site visit. Usual travel charges will apply. If any additional work arises from inspections this work will be charged out at an hourly rate plus deliveries if required.`,

		erectDismantleTotal: 0,
		additionalTotal: 0,
		weekTotal: 0,
		total: 0,

		rates: ratesInfo.data,
		zones: [
			// Starting at 1, make an object of id, zone_id, and zone_label for the following zones Entrance void, Void Protection, Mobile scaffolding, First Floor Edge Protection, Balcony Edge Protection,Hang On Platform ,First Floor Light duty Platform ,Roof Handrail ,Quickally ,Temporary Stair ,Pool Void ,Trestle/Plank
			{ id: 1, zone_id: 1, zone_label: 'Entrance void' },
			{ id: 2, zone_id: 2, zone_label: 'Void Protection' },
			{ id: 3, zone_id: 3, zone_label: 'Mobile scaffolding' },
			{ id: 4, zone_id: 4, zone_label: 'First Floor Edge Protection' },
			{ id: 5, zone_id: 5, zone_label: 'Balcony Edge Protection' },
			{ id: 6, zone_id: 6, zone_label: 'Hang On Platform' },
			{ id: 7, zone_id: 7, zone_label: 'First Floor Light duty Platform' },
			{ id: 8, zone_id: 8, zone_label: 'Roof Handrail' },
			{ id: 9, zone_id: 9, zone_label: 'Quickally' },
			{ id: 10, zone_id: 10, zone_label: 'Temporary Stair' },
			{ id: 11, zone_id: 11, zone_label: 'Pool Void' },
			{ id: 12, zone_id: 12, zone_label: 'Trestle/Plank' },
		],
		file_1: '',
		file_2: '',
		file_3: '',
		file_4: '',
		main_origin: '',
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: false,
		onSubmit: async (values) => {
			try {
				if (!editInfoLoaded) {
					// when the quote is new execute create a quote

					const create_quote_entrance_void_lines: DTOQuoteEntranceLine[] =
						values.quote_entrance_void_lines.map((line) => ({
							id: line.id,
							description: 'Entrance Void Protection',
							width: line.width,
							length: line.length,
							additional_travel: line.additional_travel,
							price_16_weeks: line.price_16_weeks,
							hire_fee: line.hire_fee,
							override_price: line.override_price,
						}))
					const create_quote_first_floor_edge_lines: DTOQuoteFirstFloorEdgeLine[] =
						values.quote_first_floor_edge_lines.map((line) => ({
							id: line.id,
							description: 'First Floor Edge Protection',
							strategy: line.strategy,
							lineal_metres: line.lineal_metres,
							additional_travel: line.additional_travel,
							price_2_weeks: line.price_2_weeks,
							hire_fee: line.hire_fee,
							override_price: line.override_price,
						}))

					const create_hang_on_platform_lines: DTOQuoteHangOnPlatformLine[] =
						values.quote_hang_on_platform_lines.map((line) => ({
							id: line.id,
							description: 'Hang On Platform',
							strategy: line.strategy,
							lineal_metres: line.lineal_metres,
							corners: line.corners,
							additional_travel: line.additional_travel,
							price_2_weeks: line.price_2_weeks,
							hire_fee: line.hire_fee,
							override_price: line.override_price,
						}))

					const create_mobile_lines: DTOQuoteMobileLine[] =
						values.quote_mobile_lines.map((line) => ({
							id: line.id,
							description: 'Mobile Scaffolding',
							length: line.length,
							width: line.width,
							height: line.height,
							additional_travel: line.additional_travel,
							price: line.price,
							hire_fee: line.hire_fee,
							override_price: line.override_price,
						}))

					const create_quote_roof_rail_lines: DTOQuoteRoofRailLine[] =
						values.quote_roof_rail_lines.map((line) => ({
							id: line.id,
							description: 'Roof Rail',
							strategy: line.strategy,
							lineal_metres: line.lineal_metres,
							additional_travel: line.additional_travel,
							price_2_weeks: line.price_2_weeks,
							hire_fee: line.hire_fee,
							override_price: line.override_price,
						}))

					const create_single_deck_lines: DTOQuoteSingleDeckLine[] =
						values.quote_single_deck_lines.map((line) => ({
							id: line.id,
							description: 'Single Deck Scaffold',
							strategy: line.strategy,
							lineal_metres: line.lineal_metres,
							handrail_height: line.handrail_height,
							additional_travel: line.additional_travel,
							price_4_weeks: line.price_4_weeks,
							hire_fee: line.hire_fee,
							override_price: line.override_price,
						}))

					const create_stair_lines: DTOQuoteStairLine[] =
						values.quote_stair_lines.map((line) => ({
							id: line.id,
							description: 'Stair Void Protection',
							width: line.width,
							length: line.length,
							additional_travel: line.additional_travel,
							price_16_weeks: line.price_16_weeks,
							hire_fee: line.hire_fee,
							override_price: line.override_price,
						}))

					const entranceVoidSelected = formik.values.table_options?.includes(
						'is_entrance_void_visible'
					)
					const stairVoidSelected = formik.values.table_options?.includes(
						'is_stair_void_visible'
					)
					const hangOnPlatformSelected = formik.values.table_options?.includes(
						'is_hang_on_platform_visible'
					)
					const firstFloorEdgeSelected = formik.values.table_options?.includes(
						'is_first_floor_edge_visible'
					)
					const roofRailSelected = formik.values.table_options?.includes(
						'is_roof_rail_visible'
					)
					const singleDeckScaffoldSelected =
						formik.values.table_options?.includes('is_single_deck_visible')
					const mobileScaffoldingSelected =
						formik.values.table_options?.includes(
							'is_mobile_scaffolding_visible'
						)

					const newQuote: CreateQuoteDTO = {
						job_type: values.job_type,
						quote_type: values.quote_type,
						client: Number(values.client),
						client_supervisor: values.client_supervisor,
						client_estimator: values.client_estimator,
						scope_of_work: values.scope_of_work,
						estimator: Number(values.estimator),
						fullAddress: values.fullAddress,
						street: values.street,
						street2: values.street2,
						city: values.city,
						postal: values.postal,
						terms: values.terms,
						erectDismantleTotal: Number(values.erectDismantleTotal),
						additionalTotal: Number(values.additionalTotal),
						weekTotal: Number(values.weekTotal),
						total: Number(values.total),
						variation_job_id: values.variation_job_id || null,
						file_1: values.file_1,
						file_2: values.file_2,
						file_3: values.file_3,
						file_4: values.file_4,
						table_options: values.table_options,
						main_origin: values.main_origin,
						PO_Number: values.PO_Number || '',
						longitude: 0,
						latitude: 0,
						quote_lines_entrance: entranceVoidSelected
							? create_quote_entrance_void_lines
							: [],
						quote_lines_first_floor_edge: firstFloorEdgeSelected
							? create_quote_first_floor_edge_lines
							: [],
						quote_lines_hang_on_platform: hangOnPlatformSelected
							? create_hang_on_platform_lines
							: [],
						quote_lines_mobile: mobileScaffoldingSelected
							? create_mobile_lines
							: [],
						quote_lines_roof_rail: roofRailSelected
							? create_quote_roof_rail_lines
							: [],
						quote_lines_single_deck: singleDeckScaffoldSelected
							? create_single_deck_lines
							: [],
						quote_lines_stair: stairVoidSelected ? create_stair_lines : [],
						quote_additional_lines: values.quote_additional_lines,
						rates: values.rates,
						zones: [],
					}

					const resultQuote = await createQuote(newQuote)
					if (resultQuote?.quote_id) {
						// navigate(
						// 	AppRoutes.privateRoutes.QuotesPDF.replace(
						// 		':id',
						// 		resultQuote.quote_id
						// 	)
						// )
						navigate(AppRoutes.privateRoutes.Quotes)
					}
				} else {
					const entranceVoidSelected = formik.values.table_options?.includes(
						'is_entrance_void_visible'
					)
					const stairVoidSelected = formik.values.table_options?.includes(
						'is_stair_void_visible'
					)
					const hangOnPlatformSelected = formik.values.table_options?.includes(
						'is_hang_on_platform_visible'
					)
					const firstFloorEdgeSelected = formik.values.table_options?.includes(
						'is_first_floor_edge_visible'
					)
					const roofRailSelected = formik.values.table_options?.includes(
						'is_roof_rail_visible'
					)
					const singleDeckScaffoldSelected =
						formik.values.table_options?.includes('is_single_deck_visible')
					const mobileScaffoldingSelected =
						formik.values.table_options?.includes(
							'is_mobile_scaffolding_visible'
						)
					// when the quote is edited execute update a quote
					const quoteData: QuoteDataToUpdate = {
						job_type: values.job_type,
						quote_type: values.quote_type,
						max_zones: values.max_zones,
						client: Number(values.client),
						client_supervisor: values.client_supervisor,
						client_estimator: values.client_estimator,
						scope_of_work: values.scope_of_work,
						estimator: Number(values.estimator),
						fullAddress: values.fullAddress,
						street: values.street,
						street2: values.street2,
						city: values.city,
						postal: values.postal,
						terms: values.terms,
						erectDismantleTotal: Number(values.erectDismantleTotal),
						additionalTotal: Number(values.additionalTotal),
						weekTotal: Number(values.weekTotal),
						total: Number(values.total),
						variation_job_id: null,
						PO_Number: null,
						file_1: values.file_1,
						file_2: values.file_2,
						file_3: values.file_3,
						file_4: values.file_4,
						table_options: values.table_options,
						main_origin: values.main_origin,
					}
					updateQuote(quoteData, quote_id as number)
					updateQuoteAddOns(values.quote_additional_lines, quote_id as number)
					updateQuoteLines(
						{
							quote_entrance_void_lines: entranceVoidSelected
								? values.quote_entrance_void_lines.map((line) => ({
									...line,
									description: 'Entrance Void Protection',
								}))
								: [],
							quote_stair_lines: stairVoidSelected
								? values.quote_stair_lines.map((line) => ({
									...line,
									description: 'Stair Void Protection',
								}))
								: [],
							quote_hang_on_platform_lines: hangOnPlatformSelected
								? values.quote_hang_on_platform_lines.map((line) => ({
									...line,
									description: 'Hang On Platform',
								}))
								: [],
							quote_first_floor_edge_lines: firstFloorEdgeSelected
								? values.quote_first_floor_edge_lines.map((line) => ({
									...line,
									description: 'First Floor Edge Protection',
								}))
								: [],
							quote_roof_rail_lines: roofRailSelected
								? values.quote_roof_rail_lines.map((line) => ({
									...line,
									description: 'Roof Rail',
								}))
								: [],
							quote_single_deck_lines: singleDeckScaffoldSelected
								? values.quote_single_deck_lines.map((line) => ({
									...line,
									description: 'Single Deck Scaffold',
								}))
								: [],
							quote_mobile_lines: mobileScaffoldingSelected
								? values.quote_mobile_lines.map((line) => ({
									...line,
									description: 'Mobile Scaffolding',
								}))
								: [],
						},
						quote_id as number
					)
					updateQuoteRates(values.rates, quote_id as number)
					updateQuoteZones(values.zones, quote_id as number)

					if (backToMainTable) navigate(AppRoutes.privateRoutes.Quotes)
					else
						navigate(
							AppRoutes.privateRoutes.QuotesDetail.replace(
								':id',
								String(quote_id)
							)
						)
				}
			} catch (error) {
				console.error(error)
			}
		},
	})

	//console.log('formik', formik.values)

	const quoteHangOnPlatformHandlers: IQuoteLineHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteHangOnPlatformLinesFn.addNewQuoteLine(
				formik.values.quote_hang_on_platform_lines
			)
			formik.setFieldValue('quote_hang_on_platform_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteHangOnPlatformLinesFn.removeQuoteLine(
				index,
				formik.values.quote_hang_on_platform_lines
			)
			formik.setFieldValue('quote_hang_on_platform_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteHangOnPlatformLinesFn.updateQuoteLine(
				index,
				formik.values.quote_hang_on_platform_lines,
				field,
				value
			)
			newQuoteLines = quoteHangOnPlatformLinesFn.calculateQuoteLines(
				formik.values.main_origin,
				newQuoteLines
			)
			formik.setFieldValue('quote_hang_on_platform_lines', newQuoteLines)
		},
	}

	const quoteFirstFloorEdgeHandlers: IQuoteLineHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteFirstFloorEdgeLinesFn.addNewQuoteLine(
				formik.values.quote_first_floor_edge_lines
			)
			formik.setFieldValue('quote_first_floor_edge_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteFirstFloorEdgeLinesFn.removeQuoteLine(
				index,
				formik.values.quote_first_floor_edge_lines
			)
			formik.setFieldValue('quote_first_floor_edge_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteFirstFloorEdgeLinesFn.updateQuoteLine(
				index,
				formik.values.quote_first_floor_edge_lines,
				field,
				value
			)
			newQuoteLines = quoteFirstFloorEdgeLinesFn.calculateQuoteLines(
				formik.values.main_origin,
				newQuoteLines
			)
			formik.setFieldValue('quote_first_floor_edge_lines', newQuoteLines)
		},
	}

	const quoteRoofRailHandlers: IQuoteLineHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteRoofRailLinesFn.addNewQuoteLine(
				formik.values.quote_roof_rail_lines
			)
			formik.setFieldValue('quote_roof_rail_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteRoofRailLinesFn.removeQuoteLine(
				index,
				formik.values.quote_roof_rail_lines
			)
			formik.setFieldValue('quote_roof_rail_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteRoofRailLinesFn.updateQuoteLine(
				index,
				formik.values.quote_roof_rail_lines,
				field,
				value
			)
			newQuoteLines = quoteRoofRailLinesFn.calculateQuoteLines(
				formik.values.main_origin,
				newQuoteLines
			)
			formik.setFieldValue('quote_roof_rail_lines', newQuoteLines)
		},
	}

	const quoteSingleDeckLinesHandlers: IQuoteLineHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteSingleDeckLinesFn.addNewQuoteLine(
				formik.values.quote_single_deck_lines
			)
			formik.setFieldValue('quote_single_deck_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteSingleDeckLinesFn.removeQuoteLine(
				index,
				formik.values.quote_single_deck_lines
			)
			formik.setFieldValue('quote_single_deck_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteSingleDeckLinesFn.updateQuoteLine(
				index,
				formik.values.quote_single_deck_lines,
				field,
				value
			)
			newQuoteLines = quoteSingleDeckLinesFn.calculateQuoteLines(
				formik.values.main_origin,
				newQuoteLines
			)
			formik.setFieldValue('quote_single_deck_lines', newQuoteLines)
		},
	}

	const quoteStairVoidLinesHandlers: IQuoteLineHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteStairVoidLinesFn.addNewQuoteLine(
				formik.values.quote_stair_lines
			)
			formik.setFieldValue('quote_stair_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteStairVoidLinesFn.removeQuoteLine(
				index,
				formik.values.quote_stair_lines
			)
			formik.setFieldValue('quote_stair_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteStairVoidLinesFn.updateQuoteLine(
				index,
				formik.values.quote_stair_lines,
				field,
				value
			)
			newQuoteLines = quoteStairVoidLinesFn.calculateQuoteLines(
				formik.values.main_origin,
				newQuoteLines
			)
			formik.setFieldValue('quote_stair_lines', newQuoteLines)
		},
	}

	const quoteMobileScaffoldingLinesHandlers: IQuoteLineHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteMobileScaffoldingLinesFn.addNewQuoteLine(
				formik.values.quote_mobile_lines
			)
			formik.setFieldValue('quote_mobile_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteMobileScaffoldingLinesFn.removeQuoteLine(
				index,
				formik.values.quote_mobile_lines
			)
			formik.setFieldValue('quote_mobile_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteMobileScaffoldingLinesFn.updateQuoteLine(
				index,
				formik.values.quote_mobile_lines,
				field,
				value
			)
			newQuoteLines = quoteMobileScaffoldingLinesFn.calculateQuoteLines(
				formik.values.main_origin,
				newQuoteLines
			)
			formik.setFieldValue('quote_mobile_lines', newQuoteLines)
		},
	}

	const quoteEntranceVoidLinesHandlers: IQuoteLineHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteEntranceVoidLinesFn.addNewQuoteLine(
				formik.values.quote_entrance_void_lines
			)
			formik.setFieldValue('quote_entrance_void_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteEntranceVoidLinesFn.removeQuoteLine(
				index,
				formik.values.quote_entrance_void_lines
			)
			formik.setFieldValue('quote_entrance_void_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteEntranceVoidLinesFn.updateQuoteLine(
				index,
				formik.values.quote_entrance_void_lines,
				field,
				value
			)
			newQuoteLines = quoteEntranceVoidLinesFn.calculateQuoteLines(
				formik.values.main_origin,
				newQuoteLines
			)
			formik.setFieldValue('quote_entrance_void_lines', newQuoteLines)
		},
	}

	const quoteAdditionalLinesHandlers: IQuoteAdditionalLinesHandlers = {
		addNewQuoteAdditionalLine: () => {
			const newline = quoteAdditionalLinesFn.addNewQuoteLine(
				formik.values.quote_additional_lines
			)
			formik.setFieldValue('quote_additional_lines', newline)
		},
		removeQuoteAdditionalLine: (index: number) => {
			const lines = quoteAdditionalLinesFn.removeQuoteLine(
				index,
				formik.values.quote_additional_lines
			)
			formik.setFieldValue('quote_additional_lines', lines)
		},
		updateQuoteAdditionalLine: (
			index: number,
			field: string,
			value: string | number
		) => {
			let lines = quoteAdditionalLinesFn.updateQuoteLine(
				index,
				formik.values.quote_additional_lines,
				field,
				value
			)
			lines = quoteAdditionalLinesFn.calculateLines(lines, formik.values.rates)

			formik.setFieldValue('quote_additional_lines', lines)
		},
	}

	const ratesHandlers: IRatesHandlers = {
		addNewRate: () =>
			ratesFn.addNewRate(formik.values.rates, formik.setFieldValue),
		removeRate: (index: number) =>
			ratesFn.removeRate(index, formik.values.rates, formik.setFieldValue),
		updateRate: (index: number, field: string, value: string | number) =>
			ratesFn.updateRate(
				index,
				formik.values.rates,
				formik.setFieldValue,
				field,
				value
			),
	}

	const zonesHandlers: IZonesHandlers = {
		addNewZone: (zone_id: number) =>
			zonesFn.addNewZone(zone_id, formik.values.zones, formik.setFieldValue),
		removeZone: (index: number) =>
			zonesFn.removezone(index, formik.values.zones, formik.setFieldValue),
		updateZone: (index: number, field: string, value: string | number) =>
			zonesFn.updateZone(
				index,
				formik.values.zones,
				formik.setFieldValue,
				field,
				value
			),
	}

	useEffect(() => {
		// TODO: FIXD THIS SHIT, relied on quote_lines, should rely on new ones or be replaced
		if (
			quote_data &&
			quote_entrance_lines &&
			quote_stair_lines &&
			quote_hang_on_platform_lines &&
			quote_first_floor_edge_lines &&
			quote_roof_rail_lines &&
			quote_single_deck_lines &&
			quote_mobile_lines &&
			quote_addons &&
			quote_rates &&
			quote_zones &&
			!editInfoLoaded
		) {
			const newData = assignDataToEdit({
				quote_data,
				quote_entrance_lines,
				quote_stair_lines,
				quote_hang_on_platform_lines,
				quote_first_floor_edge_lines,
				quote_roof_rail_lines,
				quote_single_deck_lines,
				quote_mobile_lines,
				quote_addons,
				quote_rates,
				quote_zones,
			})
			formik.setValues(newData)
			setEditInfoLoaded(true)
		}
	}, [
		quote_data,
		quote_entrance_lines,
		quote_stair_lines,
		quote_hang_on_platform_lines,
		quote_first_floor_edge_lines,
		quote_roof_rail_lines,
		quote_single_deck_lines,
		quote_mobile_lines,
		quote_addons,
		quote_rates,
		quote_zones,
	])

	useEffect(() => {
		if (ratesInfo.data && !editInfoLoaded) {
			formik.setFieldValue('rates', ratesInfo.data)
		}
	}, [ratesInfo.data])

	// Check the quote lines when the zones, rates or estimatedWay changes
	useEffect(() => {
		let newAdditionalLines = quoteAdditionalLinesFn.calculateLines(
			formik.values.quote_additional_lines,
			formik.values.rates
		)
		formik.setFieldValue('quote_additional_lines', newAdditionalLines)
	}, [formik.values.rates])

	//Functions to calculate the totals
	useEffect(() => {
		const entranceTotal = formik.values.quote_entrance_void_lines.reduce(
			(acc, line) => acc + Number(line.price_16_weeks) + Number(line.additional_travel),
			0
		)
		const stairVoidTotal = formik.values.quote_stair_lines.reduce(
			(acc, line) => acc + Number(line.price_16_weeks) + Number(line.additional_travel),
			0
		)
		const mobileScaffoldingTotal = formik.values.quote_mobile_lines.reduce(
			(acc, line) => acc + Number(line.price) + Number(line.additional_travel),
			0
		)
		const hangOnPlatformTotal =
			formik.values.quote_hang_on_platform_lines.reduce(
				(acc, line) => acc + Number(line.price_2_weeks) + Number(line.additional_travel),
				0
			)
		const firstFloorEdgeTotal =
			formik.values.quote_first_floor_edge_lines.reduce(
				(acc, line) => acc + Number(line.price_2_weeks) + Number(line.additional_travel),
				0
			)
		const roofRailTotal = formik.values.quote_roof_rail_lines.reduce(
			(acc, line) => acc + Number(line.price_2_weeks) + Number(line.additional_travel),
			0
		)
		const singleDeckTotal = formik.values.quote_single_deck_lines.reduce(
			(acc, line) => acc + Number(line.price_4_weeks) + Number(line.additional_travel),
			0
		)
		const entranceVoidSelected = formik.values.table_options?.includes(
			'is_entrance_void_visible'
		)
		const stairVoidSelected = formik.values.table_options?.includes(
			'is_stair_void_visible'
		)
		const hangOnPlatformSelected = formik.values.table_options?.includes(
			'is_hang_on_platform_visible'
		)
		const firstFloorEdgeSelected = formik.values.table_options?.includes(
			'is_first_floor_edge_visible'
		)
		const roofRailSelected = formik.values.table_options?.includes(
			'is_roof_rail_visible'
		)
		const singleDeckScaffoldSelected = formik.values.table_options?.includes(
			'is_single_deck_visible'
		)
		const mobileScaffoldingSelected = formik.values.table_options?.includes(
			'is_mobile_scaffolding_visible'
		)
		const erectDismantleTotal =
			(entranceVoidSelected ? entranceTotal : 0) +
			(stairVoidSelected ? stairVoidTotal : 0) +
			(mobileScaffoldingSelected ? mobileScaffoldingTotal : 0) +
			(hangOnPlatformSelected ? hangOnPlatformTotal : 0) +
			(firstFloorEdgeSelected ? firstFloorEdgeTotal : 0) +
			(roofRailSelected ? roofRailTotal : 0) +
			(singleDeckScaffoldSelected ? singleDeckTotal : 0)
		const additionalTotal = totalsFn.calculateAdditionalTotal(
			formik.values.quote_additional_lines
		)
		const entranceVoidWeek = formik.values.quote_entrance_void_lines.reduce(
			(acc, line) => acc + (line.hire_fee ? Number(line.hire_fee) : 0),
			0
		)
		const stairVoidWeek = formik.values.quote_stair_lines.reduce(
			(acc, line) => acc + (line.hire_fee ? Number(line.hire_fee) : 0),
			0
		)
		const mobileScaffoldWeek = formik.values.quote_mobile_lines.reduce(
			(acc, line) => acc + (line.hire_fee ? Number(line.hire_fee) : 0),
			0
		)
		const hangOnPlatformWeek =
			formik.values.quote_hang_on_platform_lines.reduce(
				(acc, line) => acc + Number(line.hire_fee) * 7,
				0
			)
		const firstFloorEdgeWeek =
			formik.values.quote_first_floor_edge_lines.reduce(
				(acc, line) => acc + Number(line.hire_fee) * 7,
				0
			)
		const roofRailWeek = formik.values.quote_roof_rail_lines.reduce(
			(acc, line) => acc + Number(line.hire_fee) * 7,
			0
		)
		const singleDeckWeek = formik.values.quote_single_deck_lines.reduce(
			(acc, line) => acc + Number(line.hire_fee),
			0
		)
		const weekTotal =
			(entranceVoidSelected ? entranceVoidWeek : 0) +
			(stairVoidSelected ? stairVoidWeek : 0) +
			(mobileScaffoldingSelected ? mobileScaffoldWeek : 0) +
			(hangOnPlatformSelected ? hangOnPlatformWeek : 0) +
			(firstFloorEdgeSelected ? firstFloorEdgeWeek : 0) +
			(roofRailSelected ? roofRailWeek : 0) +
			(singleDeckScaffoldSelected ? singleDeckWeek : 0)
		const total = erectDismantleTotal + additionalTotal
		//console.log('ed', erectDismantleTotal)

		formik.setFieldValue('erectDismantleTotal', erectDismantleTotal)
		formik.setFieldValue('additionalTotal', additionalTotal)
		formik.setFieldValue('weekTotal', weekTotal)
		formik.setFieldValue('total', total)
	}, [
		formik.values.quote_additional_lines,
		formik.values.quote_entrance_void_lines,
		formik.values.quote_stair_lines,
		formik.values.quote_mobile_lines,
		formik.values.quote_hang_on_platform_lines,
		formik.values.quote_first_floor_edge_lines,
		formik.values.quote_roof_rail_lines,
		formik.values.quote_single_deck_lines,
		formik.values.table_options,
	])

	useEffect(() => {
		// Call update on all quote lines when main_origin changes to trigger recalculation of additional travel
		quoteEntranceVoidLinesHandlers.updateQuoteLine(
			0,
			'manual_trigger',
			formik.values.main_origin
		)
		quoteStairVoidLinesHandlers.updateQuoteLine(
			0,
			'manual_trigger',
			formik.values.main_origin
		)
		quoteMobileScaffoldingLinesHandlers.updateQuoteLine(
			0,
			'manual_trigger',
			formik.values.main_origin
		)
		quoteHangOnPlatformHandlers.updateQuoteLine(
			0,
			'manual_trigger',
			formik.values.main_origin
		)
		quoteFirstFloorEdgeHandlers.updateQuoteLine(
			0,
			'manual_trigger',
			formik.values.main_origin
		)
		quoteRoofRailHandlers.updateQuoteLine(
			0,
			'manual_trigger',
			formik.values.main_origin
		)
		quoteSingleDeckLinesHandlers.updateQuoteLine(
			0,
			'manual_trigger',
			formik.values.main_origin
		)
	}, [formik.values.main_origin])

	const tableOptions = [
		{ label: 'Entrance Void', value: 'is_entrance_void_visible' },
		{ label: 'Stair Void', value: 'is_stair_void_visible' },
		{ label: 'Hang On Platform', value: 'is_hang_on_platform_visible' },
		{ label: 'First Floor Edge', value: 'is_first_floor_edge_visible' },
		{ label: 'Roof Rail', value: 'is_roof_rail_visible' },
		{ label: 'Single Deck Scaffolding', value: 'is_single_deck_visible' },
		{ label: 'Mobile Scaffolding', value: 'is_mobile_scaffolding_visible' },
	]

	return {
		formik,
		quoteEntranceVoidLinesHandlers,
		quoteStairVoidLinesHandlers,
		quoteMobileScaffoldingLinesHandlers,
		quoteFirstFloorEdgeHandlers,
		quoteHangOnPlatformHandlers,
		quoteRoofRailHandlers,
		quoteSingleDeckLinesHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
		tableOptions,
	}
}
