import { Spinner } from 'common'
import { QuotePDFViewer } from 'components/Quotes'

import { useParams } from 'react-router-dom'
import { QuoteServices } from 'services'

export const QuotePdfPage = () => {
	const { id } = useParams()
	const quoteId = parseInt(id || '') || undefined
	const { data: quote, isLoading: quote_loading } =
		QuoteServices.useQuoteById(quoteId)

	const {
		data: quote_entrance_lines,
		isLoading: quote_entrance_lines_loading,
	} = QuoteServices.useQuoteEntranceLinesByQuoteID(quoteId)

	const {
		data: quote_first_floor_edge_lines,
		isLoading: quote_first_floor_edge_lines_loading,
	} = QuoteServices.useQuoteFirstFloorEdgeLinesByQuoteID(quoteId)

	const {
		data: quote_hang_on_platform_lines,
		isLoading: quote_hang_on_platform_lines_loading,
	} = QuoteServices.useQuoteHangOnPlatformLinesByQuoteID(quoteId)

	const { data: quote_mobile_lines, isLoading: quote_mobile_lines_loading } =
		QuoteServices.useQuoteMobileLinesByQuoteID(quoteId)

	const {
		data: quote_roof_rail_lines,
		isLoading: quote_roof_rail_lines_loading,
	} = QuoteServices.useQuoteRoofRailLinesByQuoteID(quoteId)

	const {
		data: quote_single_deck_lines,
		isLoading: quote_single_deck_lines_loading,
	} = QuoteServices.useQuoteSingleDeckLinesByQuoteID(quoteId)

	const { data: quote_stair_lines, isLoading: quote_stair_lines_loading } =
		QuoteServices.useQuoteStairLinesByQuoteID(quoteId)

	const quote_lines = {
		quote_entrance_void_lines: quote_entrance_lines || [],
		quote_stair_lines: quote_stair_lines || [],
		quote_hang_on_platform_lines: quote_hang_on_platform_lines || [],
		quote_first_floor_edge_lines: quote_first_floor_edge_lines || [],
		quote_roof_rail_lines: quote_roof_rail_lines || [],
		quote_single_deck_lines: quote_single_deck_lines || [],
		quote_mobile_lines: quote_mobile_lines || [],
	}

	const { data: quote_addons, isLoading: quote_addons_loading } =
		QuoteServices.useQuoteAddonsById(quoteId)

	if (
		quote_loading ||
		quote_entrance_lines_loading ||
		quote_first_floor_edge_lines_loading ||
		quote_hang_on_platform_lines_loading ||
		quote_mobile_lines_loading ||
		quote_roof_rail_lines_loading ||
		quote_single_deck_lines_loading ||
		quote_stair_lines_loading ||
		quote_addons_loading
	) {
		return <Spinner />
	}

	return (
		<>
			<QuotePDFViewer
				quote_data={quote}
				quote_lines={quote_lines}
				quote_addons={quote_addons}
			/>
		</>
	)
}
