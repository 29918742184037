import React, { useEffect, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { AppConfig } from 'config'
import { MapIcon } from '@heroicons/react/24/outline'
import axios from 'axios'

interface AddressProps {
	streetId2: string
	cityId: string
	postalId: string
	street2Val: string
	cityVal: string
	postalVal: string
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (field: string, value: string) => void
	displayDistance?: boolean
	main_origin?: string
}

export function SearchAddress({
	streetId2,
	cityId,
	postalId,
	street2Val,
	cityVal,
	postalVal,
	handleChange,
	handleBlur,
	setFieldValue,
	displayDistance = false,
	main_origin,
}: AddressProps) {
	const googleApiKey = AppConfig.GoogleMapsApiKey
	const [street2, setStreet2] = useState<string>('')
	const [city, setCity] = useState<string>('')
	const [postalCode, setPostalCode] = useState<string>('')

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handlePlaceSelect = async (place: any) => {
		const addressComponents = place.address_components
		if (displayDistance) {
			const origin = '2/10 Franklin St, Adelaide SA 5000, Australia'
			const destination = place.formatted_address

			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						'X-Goog-Api-Key': googleApiKey,
						'X-Goog-FieldMask':
							'routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline',
					},
				}
				const requestData = {
					origin: {
						address: origin,
					},
					destination: {
						address: destination,
					},
					travelMode: 'DRIVE',
					units: 'IMPERIAL',
				}
				const response = await axios.post(
					'https://routes.googleapis.com/directions/v2:computeRoutes',
					requestData,
					config
				)

				const mainOrigin = await (
					response.data.routes[0].distanceMeters / 1000
				).toFixed(1)
				setFieldValue('main_origin', mainOrigin)
			} catch (error) {
				console.error(error)
			}
		}

		let street2 = ''
		let city = ''
		let postalCode = ''

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		addressComponents.forEach((component: any) => {
			const { types } = component

			if (types.includes('street_number')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += ` ${component.long_name}`
				}
			}

			if (types.includes('route')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += ` ${component.long_name}`
				}
			}

			if (types.includes('sublocality')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += `, ${component.long_name}`
				}
			}

			if (types.includes('locality')) {
				if (city === '') {
					city = component.long_name
				} else {
					city += `, ${component.long_name}`
				}
				cityVal = city
			}

			// if (types.includes('administrative_area_level_1')) {
			// 	if (city === '') {
			// 		city = component.long_name
			// 	} else {
			// 		city += `, ${component.long_name}`
			// 	}
			// }

			if (types.includes('postal_code')) {
				postalCode = component.long_name
			}
		})

		setStreet2(street2)
		setCity(city)
		setPostalCode(postalCode)

		setFieldValue('street2', street2)
		setFieldValue('street', place.formatted_address)
		setFieldValue('postal', postalCode)
		setFieldValue('city', city)
	}

	useEffect(() => {
		setStreet2(street2Val)
		setCity(cityVal)
		setPostalCode(postalVal)
	}, [cityVal, street2Val, postalVal])

	return (
		<div className="px-2 py-4 mt-10 sm:mt-0">
			<div className="md:grid md:grid-cols-6 md:gap-6">
				<div className="col-span-6">
					<Autocomplete
						apiKey={googleApiKey}
						placeholder=""
						style={{ width: '100%' }}
						className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onPlaceSelected={(place) => {
							handlePlaceSelect(place)
						}}
						options={{
							types: ['address'],
							componentRestrictions: { country: 'au' },
						}}
					/>
				</div>
				<div className="col-span-6">
					{displayDistance ? (
						<div>
							<div className="flex gap-1 mb-2">
								<MapIcon className="h-5 w-5" aria-hidden="true" />
								<label
									htmlFor={streetId2}
									className="block text-sm font-medium text-gray-700">
									Distance to GPO
								</label>
							</div>

							<input
								type="text"
								name={streetId2}
								id={streetId2}
								autoComplete="street-address"
								className={`border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3 ${
									Number(main_origin) >= 250 ? 'bg-red-400' : ''
								}`}
								onChange={handleChange}
								onBlur={handleBlur}
								value={main_origin ? `${main_origin} KM` : ''}
								disabled
							/>
						</div>
					) : null}
				</div>

				<div className="col-span-6">
					<label
						htmlFor={streetId2}
						className="block text-sm font-medium text-gray-700">
						Address
					</label>
					<input
						type="text"
						name={streetId2}
						id={streetId2}
						autoComplete="street-address"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={handleChange}
						onBlur={handleBlur}
						value={street2}
					/>
				</div>

				<div className="col-span-6 sm:col-span-6 lg:col-span-4">
					<label
						htmlFor="city"
						className="block text-sm font-medium text-gray-700">
						City
					</label>
					<input
						type="text"
						name={cityId}
						id={cityId}
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={handleChange}
						onBlur={handleBlur}
						value={city}
					/>
				</div>
				<div className="col-span-6 sm:col-span-2 lg:col-span-2">
					<label
						htmlFor="postal-code"
						className="block text-sm font-medium text-gray-700">
						Postal Code
					</label>
					<input
						type="text"
						name={postalId}
						id={postalId}
						autoComplete="postal-code"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={handleChange}
						onBlur={handleBlur}
						value={postalCode}
					/>
				</div>
			</div>
		</div>
	)
}
