import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { JobVisitsSchedulerUtils } from 'common/Scheduler'
import { Spinner } from 'common'
import { CrewsServices } from 'services'
interface IFormatResource {
	id: string
	title: string
}

export function Scheduler() {
	const { data: crewsData, isLoading: crewsLoading } = CrewsServices.useCrews()

	const {
		visitsLoading,
		headerToolbar,
		eventContent,
		formatEvents,
		eventDropCrewResource,
		formatEventsCrewResource,
	} = JobVisitsSchedulerUtils()
	const formatResources = () => {
		if (crewsData) {
			return crewsData.map((crew: any) => ({
				id: crew.id,
				title: crew?.vehicle.CodeName,
			}))
		}
		return []
	}

	if (visitsLoading || crewsLoading) {
		return <Spinner />
	}

	return (
		<StyledWrapper>
			<FullCalendar
				resourceAreaHeaderContent="Crews"
				resourceAreaWidth="8%"
				headerToolbar={{
					...headerToolbar,
				}}
				slotMinWidth={100}
				views={{
					Day: {
						type: 'resourceTimeGridDay',
						allDaySlot: true,
						slotLabelContent: (arg) => {
							return ''
						},
						slotDuration: { days: 1 },
						slotLaneContent: (arg) => {
							return ''
						},
						slotLabelInterval: { days: 1 },
						allDayText: 'Visits',
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimeGridPlugin,
				]}
				initialView="Day"
				height="auto"
				editable
				selectable
				dayMaxEvents
				firstDay={1}
				resources={formatResources()}
				events={formatEventsCrewResource()}
				eventDrop={eventDropCrewResource}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			/>
		</StyledWrapper>
	)
}
