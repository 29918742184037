import { CrewsServices, JobsServices, VisitServices } from 'services'
import EventView from './EventContent'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

export function JobVisitsSchedulerUtils() {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const { updateVisit } = VisitServices.useUpdateVisit()
	const { data: crewsData, isLoading: crewsLoading } = CrewsServices.useCrews()
	const { data: tasksData, isLoading: tasksLoading } = JobsServices.useTask()
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}
	// console.log(visitsData)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async ({ event }: any) => {
		const visitId = Number(event?.id)
		const newDate = moment(event?.start).format('DD/MM/YYYY')
		if (visitId && newDate) {
			const payload = {
				date: newDate,
			}
			await updateVisit(visitId, payload)
		}
	}

	const eventDropCrewResource = async ({ event, newResource }: any) => {
		if (!event || !newResource) return
		const visitId = Number(event?.id)
		const crew = crewsData.find(
			(crew: any) => crew.id === Number(newResource.id)
		)
		console.log(crew, crewsData)
		if (visitId && crew) {
			const payload = {
				crew_id: crew.id,
				vehicle_ids: crew?.vehicle_id ? [crew.vehicle_id] : [],
				staff_ids: crew.staff_members.map((staff: any) => staff.id),
				team_leader_id: crew.crew_leader_id,
				staff_labels: crew.staff_members.map((staff: any) => staff.staff_name),
			}
			await updateVisit(visitId, payload)
		}
	}

	const formatEvents = () => {
		if (!visitsLoading && visitsData) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return visitsData.map((visit: any) => ({
				id: visit.id,
				resourceId: visit?.teamLeaderData?.id,
				job_id: visit?.jobData?.job_num,
				job_type: visit?.jobData?.job_type,
				title: visit.visit_status,
				teamLeader: visit.teamLeaderData?.staff_name || '',
				crew_vehicle: visit?.crewData?.vehicle?.CodeName || '',
				tasks: tasksData?.filter((task: any) =>
					visit.task_ids.includes(task.id)
				),
				type: visit?.type,
				site: visit.jobData?.site,
				start: moment(visit?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			}))
		}
		return []
	}

	const formatEventsCrewResource = () => {
		if (!visitsLoading && visitsData) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return visitsData.map((visit: any) => {
				return {
					id: visit.id,
					resourceId: visit?.crewData?.id,
					job_id: visit?.jobData?.job_num,
					job_type: visit?.jobData?.job_type,
					title: visit.visit_status,
					teamLeader: visit.teamLeaderData?.staff_name || '',
					crew_vehicle: visit?.crewData?.vehicle?.CodeName || '',
					tasks: tasksData?.filter((task: any) =>
						visit.task_ids.includes(task.id)
					),
					type: visit?.type,
					site: visit.jobData?.site,
					start: moment(visit?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
				}
			})
		}
		return []
	}

	return {
		formatEvents,
		visitsData,
		visitsLoading,
		headerToolbar,
		eventDrop,
		eventContent,
		eventDropCrewResource,
		formatEventsCrewResource,
	}
}
