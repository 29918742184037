import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteZones,
	IRates,
	IQuoteLineHandlers,
	IQuoteMobileLine,
	IQuoteForm,
} from 'models'
import { LockClosedIcon, LockOpenIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog } from 'common'
import '../customStyle.css'
import { Dropdown } from 'primereact/dropdown'
import { FormikProps } from 'formik'

interface QuoteLineProps {
	data: IQuoteMobileLine
	quoteLinesHandlers: IQuoteLineHandlers
	index: number
	formik: FormikProps<IQuoteForm>
}

const widthOptions = [
	{
		label: '0.7',
		value: 0.7,
	},
	{
		label: '1.2',
		value: 1.2,
	},
	{
		label: '1.8',
		value: 1.8,
	},
	{
		label: '2.4',
		value: 2.4,
	},
]

const heightOptions = [
	{
		label: '1',
		value: 1,
	},
	{
		label: '2.5',
		value: 2.5,
	},
	{
		label: '3',
		value: 3,
	},
	{
		label: '3.5',
		value: 3.5,
	},
	{
		label: '4',
		value: 4,
	},
	{
		label: '4.5',
		value: 4.5,
	},
	{
		label: '5',
		value: 5,
	},
	{
		label: '5.5',
		value: 5.5,
	},
	{
		label: '6',
		value: 6,
	},
	{
		label: '6.5',
		value: 6.5,
	},
	{
		label: '7',
		value: 7,
	},
	{
		label: '10',
		value: 10,
	},
]

const lengthOptions = [
	{
		label: '2',
		value: 2,
	},
	{
		label: '2.5',
		value: 2.5,
	},
	{
		label: '3',
		value: 3,
	}
]


export const QuoteMobileScaffoldingLine = ({
	data,
	index,
	quoteLinesHandlers,
	formik,
}: QuoteLineProps) => {
	const handleProductLineChange = (
		index: number,
		field: any,
		value: string | number | boolean
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	return (
		<tr>
			<td className="px-0.5 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<input
					id={`category${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.category || ''}
					onChange={(e) =>
						handleProductLineChange(index, 'category', e.target.value)
					}
					disabled
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<Dropdown
					//hasLabel={false}
					options={widthOptions}
					id={`width${index}`}
					value={data?.width || 0}
					onChange={(e) => {
						handleProductLineChange(index, 'width', e.target.value)
					}}
					className="w-full h-7 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-center"
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<Dropdown
					//hasLabel={false}
					options={lengthOptions}
					id={`length${index}`}
					value={data?.length || 0}
					onChange={(e) => {
						handleProductLineChange(index, 'length', e.target.value)
					}}
					className="w-full h-7 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-center"
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<Dropdown
					//hasLabel={false}
					options={heightOptions}
					id={`height${index}`}
					value={data?.height || 0}
					onChange={(e) => {
						handleProductLineChange(index, 'height', e.target.value)
					}}
					className="w-full h-7 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-center"
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`additional_travel${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={`$${data?.additional_travel?.toFixed(2) || ''}`}
					disabled
					onChange={(e) =>
						handleProductLineChange(index, 'additional_travel', e.target.value)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
			<div className="flex justify-start items-center">
			{!formik.values.quote_mobile_lines?.[index]?.override_price && (
					<LockClosedIcon
						onClick={() => {
							handleProductLineChange(index, 'override_price', true)
						}}
						className="flex-shrink-0 h-4 w-4 text-red-500 mr-2"
					/>
				)}
				{formik.values.quote_mobile_lines?.[index]?.override_price && (
					<LockOpenIcon
						onClick={() => {
							handleProductLineChange(index, 'override_price', false)
						}}
						className="flex-shrink-0 h-4 w-4 text-green-500 mr-2"
					/>
				)}
				<input
					id={`price${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.price || ''}
					disabled={
						!formik.values.quote_mobile_lines?.[index]?.override_price
					}
					onChange={(e) =>
						handleProductLineChange(index, 'price', e.target.value)
					}
				/>
				</div>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`hire_fee${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.hire_fee || 0}
					disabled
					onChange={(e) =>
						handleProductLineChange(index, 'hire_fee', e.target.value)
					}
				/>
			</td>
		</tr>
	)
}
