import { IQuoteRoofRailLine, IQuoteZones, IRates } from 'models'

const initLine: IQuoteRoofRailLine = {
	id: null,
	strategy: 1,
	lineal_metres: 0,
	unit_price: 0,
	additional_travel: 0,
	price_2_weeks: 0,
	hire_fee: 0,
	override_price: false,
}

const addNewQuoteLine = (
	quote_lines: IQuoteRoofRailLine[]
): IQuoteRoofRailLine[] => {
	quote_lines.push(initLine)
	return calculateQuoteLines('', quote_lines)
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteRoofRailLine[]
): IQuoteRoofRailLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteRoofRailLine[],
	field: string,
	value: string | number | boolean
): IQuoteRoofRailLine[] => {
	const newQuoteLines: IQuoteRoofRailLine[] = quote_lines.map((line, i) => {
		if (i === index) {
			const newLine = {
				...line,
				[field]: value,
			}
			return newLine
		}
		return line
	})

	return newQuoteLines
}

const calculateQuoteLines = (
	distance_gpo: string | null,
	quote_lines: IQuoteRoofRailLine[]
): IQuoteRoofRailLine[] => {
	let additionalTravel = 0
	const travelCost = Math.max(
		0,
		Number(((Number(distance_gpo) * 4 - 200) * 1.5)?.toFixed(2))
	)
	if (distance_gpo) {
		// console.log(distance_gpo)
		if (Number(distance_gpo) > 50) {
			additionalTravel = travelCost
		}
	}
	const newQuoteLines = quote_lines.map((line) => {
		const low_price = 15
		const medium_price = 16
		const high_price = 17
		const unit_price =
			line.strategy === 1
				? low_price
				: line.strategy === 2
				? medium_price
				: line.strategy === 3
				? high_price
				: 0
		line.unit_price = unit_price
		const addon_price = 160
		const daily_rate = 1.2
		line.additional_travel = additionalTravel
		if (line.override_price == false) {
			line.price_2_weeks = unit_price * line.lineal_metres + addon_price
		}
		line.hire_fee = line.lineal_metres * daily_rate
		return line
	})
	return newQuoteLines
}

export const quoteRoofRailLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateQuoteLines,
}
