import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteZones,
	IRates,
	IQuoteLineHandlers,
	IQuoteRoofRailLine,
	IQuoteForm,
} from 'models'
import { LockClosedIcon, LockOpenIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog } from 'common'
import '../customStyle.css'
import { FormikProps } from 'formik'

interface QuoteLineProps {
	data: IQuoteRoofRailLine
	quoteLinesHandlers: IQuoteLineHandlers
	index: number
	formik: FormikProps<IQuoteForm>
}

export const QuoteRoofRailLine = ({
	data,
	index,
	quoteLinesHandlers,
	formik,
}: QuoteLineProps) => {
	const handleProductLineChange = (
		index: number,
		field: any,
		value: string | number | boolean
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	return (
		<tr>
			<td className="px-0.5 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="strategy"
					options={[
						{ value: 1, label: 'Low' },
						{ value: 2, label: 'Medium' },
						{ value: 3, label: 'High' },
					]}
					id={`zone_id${data?.id}`}
					value={data?.strategy || 1}
					onChange={(e) =>
						handleProductLineChange(index, 'strategy', e.target.value)
					}
					className="w-full h-7 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`lm_${data?.id}`}
					type="number"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.lineal_metres || 0}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'lineal_metres',
							Number(e.target.value)
						)
					}
				/>
			</td>
			{/* <td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`corners_${data?.id}`}
					type="number"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.corners}
					onChange={(e) =>
						handleProductLineChange(index, 'corners', Number(e.target.value))
					}
				/>
			</td> */}
			<td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`unit_price_${data?.id}`}
					type="number"
					className={
						'h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none' +
						`${
							data?.strategy === 1
								? ' bg-green-100'
								: data?.strategy === 2
								? ' bg-yellow-100'
								: ' bg-red-100'
						}`
					}
					value={data?.unit_price || 0}
					disabled
				/>
			</td>
			<td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`additional_travel${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={`$${data?.additional_travel?.toFixed(2) || ''}`}
					disabled
					onChange={(e) =>
						handleProductLineChange(index, 'additional_travel', e.target.value)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
			<div className="flex justify-start items-center">
			{!formik.values.quote_roof_rail_lines?.[index]?.override_price && (
					<LockClosedIcon
						onClick={() => {
							handleProductLineChange(index, 'override_price', true)
						}}
						className="flex-shrink-0 h-4 w-4 text-red-500 mr-2"
					/>
				)}
				{formik.values.quote_roof_rail_lines?.[index]?.override_price && (
					<LockOpenIcon
						onClick={() => {
							handleProductLineChange(index, 'override_price', false)
						}}
						className="flex-shrink-0 h-4 w-4 text-green-500 mr-2"
					/>
				)}
				<input
					id={`price_2_weeks${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.price_2_weeks || ''}
					disabled={
						!formik.values.quote_roof_rail_lines?.[index]?.override_price
					}
					onChange={(e) =>
						handleProductLineChange(index, 'price_2_weeks', e.target.value)
					}
				/>
				</div>
			</td>
			<td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`hire_fee${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.hire_fee?.toFixed(2) || ''}
					onChange={(e) =>
						handleProductLineChange(index, 'hire_fee', e.target.value)
					}
				/>
			</td>
		</tr>
	)
}
