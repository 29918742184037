import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'
import {
	DTOQuoteEntranceLine,
	DTOQuoteFirstFloorEdgeLine,
	DTOQuoteHangOnPlatformLine,
	DTOQuoteMobileLine,
	DTOQuoteRoofRailLine,
	DTOQuoteSingleDeckLine,
	DTOQuoteStairLine,
} from 'models/quotes.model'

export const useQuoteEntranceLinesByQuoteID = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteEntranceLinesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_entrance,
				quoteId
			)
			const data: DTOQuoteEntranceLine[] = response.data.map((line: any) => {
				return {
					...line,
					category: line?.description,
					lineal_metres: line?.lineal_metres,
				} as DTOQuoteEntranceLine
			})
			return data
		} catch (error) {
			showError('Something went wrong getting quote')
			throw new Error('Something went wrong getting quote')
		}
	}

	if (!quoteId) {
		return {
			fetchQuoteEntranceLinesById,
		}
	}

	const { data, isLoading, error } = useQuery(
		['quote_entrance_lines', quoteId],
		() => fetchQuoteEntranceLinesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteEntranceLinesById }
}

export const useQuoteFirstFloorEdgeLinesByQuoteID = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteFirstFloorEdgeLinesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_first_floor_edge,
				quoteId
			)
			const data: DTOQuoteFirstFloorEdgeLine[] = response.data.map(
				(line: any) => {
					return {
						...line,
						category: line?.description,
						lineal_metres: line?.lineal_metres,
					} as DTOQuoteFirstFloorEdgeLine
				}
			)
			return data
		} catch (error) {
			showError('Something went wrong getting quote')
			throw new Error('Something went wrong getting quote')
		}
	}

	if (!quoteId) {
		return {
			fetchQuoteFirstFloorEdgeLinesById,
		}
	}

	const { data, isLoading, error } = useQuery(
		['quote_first_floor_edge_lines', quoteId],
		() => fetchQuoteFirstFloorEdgeLinesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteFirstFloorEdgeLinesById }
}

export const useQuoteHangOnPlatformLinesByQuoteID = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteHangOnPlatformLinesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_hang_on_platform,
				quoteId
			)
			const data: DTOQuoteHangOnPlatformLine[] = response.data.map(
				(line: any) => {
					return {
						...line,
						category: line?.description,
						lineal_metres: line?.lineal_metres,
					} as DTOQuoteHangOnPlatformLine
				}
			)
			return data
		} catch (error) {
			showError('Something went wrong getting quote')
			throw new Error('Something went wrong getting quote')
		}
	}

	if (!quoteId) {
		return {
			fetchQuoteHangOnPlatformLinesById,
		}
	}

	const { data, isLoading, error } = useQuery(
		['quote_hang_on_platform_lines', quoteId],
		() => fetchQuoteHangOnPlatformLinesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteHangOnPlatformLinesById }
}

export const useQuoteMobileLinesByQuoteID = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteMobileLinesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_mobile,
				quoteId
			)
			const data: DTOQuoteMobileLine[] = response.data.map((line: any) => {
				return {
					...line,
					category: line?.description,
					lineal_metres: line?.lineal_metres,
				} as DTOQuoteMobileLine
			})
			return data
		} catch (error) {
			showError('Something went wrong getting quote')
			throw new Error('Something went wrong getting quote')
		}
	}

	if (!quoteId) {
		return {
			fetchQuoteMobileLinesById,
		}
	}

	const { data, isLoading, error } = useQuery(
		['quote_mobile_lines', quoteId],
		() => fetchQuoteMobileLinesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteMobileLinesById }
}

export const useQuoteRoofRailLinesByQuoteID = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteRoofRailLinesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_roof_rail,
				quoteId
			)
			const data: DTOQuoteRoofRailLine[] = response.data.map((line: any) => {
				return {
					...line,
					category: line?.description,
					lineal_metres: line?.lineal_metres,
				} as DTOQuoteRoofRailLine
			})
			return data
		} catch (error) {
			showError('Something went wrong getting quote')
			throw new Error('Something went wrong getting quote')
		}
	}

	if (!quoteId) {
		return {
			fetchQuoteRoofRailLinesById,
		}
	}

	const { data, isLoading, error } = useQuery(
		['quote_roof_rail_lines', quoteId],
		() => fetchQuoteRoofRailLinesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteRoofRailLinesById }
}

export const useQuoteSingleDeckLinesByQuoteID = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteSingleDeckLinesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_single_deck,
				quoteId
			)
			const data: DTOQuoteSingleDeckLine[] = response.data.map((line: any) => {
				return {
					...line,
					category: line?.description,
					lineal_metres: line?.lineal_metres,
				} as DTOQuoteSingleDeckLine
			})
			return data
		} catch (error) {
			showError('Something went wrong getting quote')
			throw new Error('Something went wrong getting quote')
		}
	}

	if (!quoteId) {
		return {
			fetchQuoteSingleDeckLinesById,
		}
	}

	const { data, isLoading, error } = useQuery(
		['quote_single_deck_lines', quoteId],
		() => fetchQuoteSingleDeckLinesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteSingleDeckLinesById }
}

export const useQuoteStairLinesByQuoteID = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteStairLinesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.quote_lines_stair,
				quoteId
			)
			const data: DTOQuoteStairLine[] = response.data.map((line: any) => {
				return {
					...line,
					category: line?.description,
					lineal_metres: line?.lineal_metres,
				} as DTOQuoteStairLine
			})
			return data
		} catch (error) {
			showError('Something went wrong getting quote')
			throw new Error('Something went wrong getting quote')
		}
	}

	if (!quoteId) {
		return {
			fetchQuoteStairLinesById,
		}
	}

	const { data, isLoading, error } = useQuery(
		['quote_stair_lines', quoteId],
		() => fetchQuoteStairLinesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteStairLinesById }
}
