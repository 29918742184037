import { MakeBlobQuote } from 'components/Quotes/Pdf/MakeBlob'
import { QuoteServices } from 'services'
import { useFileUpload } from 'services/api'
export const useUploadQuote = () => {
	const { fetchQuoteById } = QuoteServices.useQuoteById()

	const { fetchQuoteEntranceLinesById } =
		QuoteServices.useQuoteEntranceLinesByQuoteID()

	const { fetchQuoteFirstFloorEdgeLinesById } =
		QuoteServices.useQuoteFirstFloorEdgeLinesByQuoteID()

	const { fetchQuoteHangOnPlatformLinesById } =
		QuoteServices.useQuoteHangOnPlatformLinesByQuoteID()

	const { fetchQuoteMobileLinesById } =
		QuoteServices.useQuoteMobileLinesByQuoteID()

	const { fetchQuoteRoofRailLinesById } =
		QuoteServices.useQuoteRoofRailLinesByQuoteID()

	const { fetchQuoteSingleDeckLinesById } =
		QuoteServices.useQuoteSingleDeckLinesByQuoteID()

	const { fetchQuoteStairLinesById } =
		QuoteServices.useQuoteStairLinesByQuoteID()

	const { fetchQuoteAddOnsById } = QuoteServices.useQuoteAddonsById()

	const { uploadFile } = useFileUpload()

	const uploadQuote = async (quote_id: number) => {
		if (!quote_id) {
			return
		}

		const quote_data = await fetchQuoteById(quote_id)
		// console.warn('Add the rest of the quote lines')

		const quote_entrance_lines = await fetchQuoteEntranceLinesById(quote_id)
		const quote_first_floor_edge_lines =
			await fetchQuoteFirstFloorEdgeLinesById(quote_id)
		const quote_hang_on_platform_lines =
			await fetchQuoteHangOnPlatformLinesById(quote_id)
		const quote_mobile_lines = await fetchQuoteMobileLinesById(quote_id)
		const quote_roof_rail_lines = await fetchQuoteRoofRailLinesById(quote_id)
		const quote_single_deck_lines = await fetchQuoteSingleDeckLinesById(
			quote_id
		)
		const quote_stair_lines = await fetchQuoteStairLinesById(quote_id)
		const quote_lines = {
			quote_entrance_void_lines: quote_entrance_lines || [],
			quote_stair_lines: quote_stair_lines || [],
			quote_hang_on_platform_lines: quote_hang_on_platform_lines || [],
			quote_first_floor_edge_lines: quote_first_floor_edge_lines || [],
			quote_roof_rail_lines: quote_roof_rail_lines || [],
			quote_single_deck_lines: quote_single_deck_lines || [],
			quote_mobile_lines: quote_mobile_lines || [],
		}

		const quote_addons = await fetchQuoteAddOnsById(quote_id)
		const blob = await MakeBlobQuote({
			quote_data,
			quote_lines,
			quote_addons,
		})
		const fileName = `Elite Quote - ${quote_data?.quote_num}.pdf`
		const file = new File([blob], fileName, { type: 'application/pdf' })
		const resultUpload = await uploadFile(file, {fileName})

		return resultUpload?.data?.url
	}

	return { uploadQuote }
}
