import { DateSelect, Dropdown, MultiSelect, SideModal, Spinner } from 'common'
import { DateSelectTypeDate } from 'common/DateSelect/DateSelectTypeDate'
import { useFormik } from 'formik'

import { CrewsServices, StaffServices, VehicleServices } from 'services'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	crew_id: string | undefined
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

const statusOptions = [
	{ label: 'Active', value: 'Active' },
	{ label: 'Inactive', value: 'Inactive' },
]

interface EditCrewDTO {
	crew_leader_id: number
	staff_members: number[]
	vehicle_id: number
}

export const EditCrewsForm = ({
	crew_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { updateCrew } = CrewsServices.useUpdateCrews()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { data: crewsData, isLoading: crewsLoading } = CrewsServices.useCrews()
	const { data: crewData, isLoading: crewLoading } =
		CrewsServices.useCrewById(crew_id)

	const { data: vehiclesData, isLoading: vehiclesLoading } =
		VehicleServices.useVehicles()

	const initialValues: EditCrewDTO = {
		crew_leader_id: crewData?.crew_leader.id,
		staff_members: crewData?.staff_members.map((staff: any) => staff.id),
		vehicle_id: crewData?.vehicle?.id,
	}

	const validationSchema = Yup.object({
		staff_members: Yup.array().min(1, 'Staff is required'),
		vehicle_id: Yup.number().required('Vehicle is required'),
		crew_leader_id: Yup.number().required('Team Leader is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const { crew_leader_id, staff_members, vehicle_id } = values

			const crewPayload = {
				crew_leader_id,
				staff_members,
				vehicle_id: Number(vehicle_id),
			}

			const resultCrew = await updateCrew(crewPayload, Number(crew_id))

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (staffLoading || vehiclesLoading || crewLoading || crewsLoading) {
		return <Spinner />
	}

	const staffWithCrew = () => {
		if (!crewsLoading && crewsData) {
			const staffWithCrew: number[] = []
			const crewsNotThisCrew = crewsData.filter(
				(crew: any) => crew.id !== Number(crew_id)
			)
			crewsNotThisCrew.map((crew: any) =>
				crew?.staff_members.map((staff: any) => staffWithCrew.push(staff.id))
			)
			return staffWithCrew
		}
		return []
	}

	const staffAlreadyCrewLeader = () => {
		if (!crewsLoading && crewsData) {
			const staffAlreadyCrewLeader: number[] = []
			const crewsNotThisCrew = crewsData.filter(
				(crew: any) => crew.id !== Number(crew_id)
			)
			crewsNotThisCrew.map((crew: any) =>
				staffAlreadyCrewLeader.push(crew.crew_leader.id)
			)
			return staffAlreadyCrewLeader
		}
		return []
	}

	const vehiclesWithCrew = () => {
		if (!crewsLoading && crewsData) {
			const vehiclesWithCrew: number[] = []
			const crewsNotThisCrew = crewsData.filter(
				(crew: any) => crew.id !== Number(crew_id)
			)
			crewsNotThisCrew.map((crew: any) =>
				vehiclesWithCrew.push(crew.vehicle.id)
			)
			return vehiclesWithCrew
		}
		return []
	}

	console.log(vehiclesData)

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="vehicle_id"
							label="Vehicle"
							options={OptionsForDropdown(
								vehiclesData.filter(
									(vehicle: any) =>
										vehiclesWithCrew().includes(vehicle.id) === false
								),
								'id',
								'CodeName'
							)}
							value={formik.values.vehicle_id}
							onChange={formik.setFieldValue}
							error={formik.errors.vehicle_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="crew_leader_id"
							label="Team Leader"
							options={OptionsForDropdown(
								staffData?.filter((staff: any) => staff.type === 'Crew Leader'),
								'id',
								'staff_name'
							)}
							value={formik.values.crew_leader_id}
							onChange={formik.setFieldValue}
							error={formik.errors.crew_leader_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<MultiSelect
							id="staff_members"
							label="Staff"
							options={OptionsForDropdownFilter(
								staffData,
								'id',
								['staff_name'],
								'status',
								'Active'
							)}
							value={formik.values.staff_members}
							onChange={formik.setFieldValue}
							error={formik.errors.staff_members}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
