import { Section, Spinner, TextArea, TwoColumnDetails } from 'common'
import { Table } from 'common/Table'
import { AppRoutes } from 'config'
import { useParams } from 'react-router-dom'
import { QuoteServices } from 'services'
import { numberFormat } from 'utilities'

export const QuoteDetails = () => {
	const { id } = useParams()
	const quoteId = parseInt(id || '') || undefined

	const { data: quote, isLoading: quote_loading } =
		QuoteServices.useQuoteById(quoteId)

	const {
		data: quote_entrance_lines,
		isLoading: quote_entrance_lines_loading,
	} = QuoteServices.useQuoteEntranceLinesByQuoteID(quoteId)

	const {
		data: quote_first_floor_edge_lines,
		isLoading: quote_first_floor_edge_lines_loading,
	} = QuoteServices.useQuoteFirstFloorEdgeLinesByQuoteID(quoteId)

	const {
		data: quote_hang_on_platform_lines,
		isLoading: quote_hang_on_platform_lines_loading,
	} = QuoteServices.useQuoteHangOnPlatformLinesByQuoteID(quoteId)

	const { data: quote_mobile_lines, isLoading: quote_mobile_lines_loading } =
		QuoteServices.useQuoteMobileLinesByQuoteID(quoteId)

	const {
		data: quote_roof_rail_lines,
		isLoading: quote_roof_rail_lines_loading,
	} = QuoteServices.useQuoteRoofRailLinesByQuoteID(quoteId)

	const {
		data: quote_single_deck_lines,
		isLoading: quote_single_deck_lines_loading,
	} = QuoteServices.useQuoteSingleDeckLinesByQuoteID(quoteId)

	const { data: quote_stair_lines, isLoading: quote_stair_lines_loading } =
		QuoteServices.useQuoteStairLinesByQuoteID(quoteId)

	const { data: quote_addons, isLoading: quote_addons_loading } =
		QuoteServices.useQuoteAddonsById(quoteId)

	const quoteLineDataLoading =
		quote_addons_loading ||
		quote_entrance_lines_loading ||
		quote_first_floor_edge_lines_loading ||
		quote_hang_on_platform_lines_loading ||
		quote_mobile_lines_loading ||
		quote_roof_rail_lines_loading ||
		quote_single_deck_lines_loading ||
		quote_stair_lines_loading

	if (quote_loading || quoteLineDataLoading) return <Spinner />

	type QuoteDataLine = {
		id: string
		type: string
		description: string
		quantity: string
		additional_travel: number
		unit_price: number
		hire_fee: number
	}

	const quote_line_data: QuoteDataLine[] = [
		...(quote_entrance_lines
			? quote_entrance_lines?.map((line) => ({
					id: `entrance_line_${line.id}`,
					type: 'Entrance Void',
					description: line.description,
					quantity: `${line.length}mm x ${line.width}mm`,
					unit_price: line.price_16_weeks,
					hire_fee: line.hire_fee,
					additional_travel: line.additional_travel,
			  }))
			: []),

		...(quote_stair_lines
			? quote_stair_lines?.map((line) => ({
					id: `stair_line_${line.id}`,
					type: 'Stair Void',
					description: line.description,
					quantity: `${line.length}mm x ${line.width}mm`,
					unit_price: line.price_16_weeks,
					hire_fee: line.hire_fee,
					additional_travel: line.additional_travel,
			  }))
			: []),

		...(quote_hang_on_platform_lines
			? quote_hang_on_platform_lines?.map((line) => ({
					id: `hang_on_platform_line_${line.id}`,
					type: 'Hang On Platform',
					description: line.description,
					quantity: `${line.lineal_metres}m x ${line.corners} corners`,
					unit_price: line.price_2_weeks,
					hire_fee: line.hire_fee,
					additional_travel: line.additional_travel,
			  }))
			: []),

		...(quote_first_floor_edge_lines
			? quote_first_floor_edge_lines?.map((line) => ({
					id: `first_floor_edge_line_${line.id}`,
					type: 'First Floor Edge',
					description: line.description,
					quantity: `${line.lineal_metres}m`,
					unit_price: line.price_2_weeks,
					hire_fee: line.hire_fee,
					additional_travel: line.additional_travel,
			  }))
			: []),

		...(quote_roof_rail_lines
			? quote_roof_rail_lines?.map((line) => ({
					id: `roof_rail_line_${line.id}`,
					type: 'Roof Rail',
					description: line.description,
					quantity: `${line.lineal_metres}m`,
					unit_price: line.price_2_weeks,
					hire_fee: line.hire_fee,
					additional_travel: line.additional_travel,
			  }))
			: []),

		...(quote_single_deck_lines
			? quote_single_deck_lines?.map((line) => ({
					id: `single_deck_line_${line.id}`,
					type: 'Single Deck Scaffolding',
					description: line.description,
					quantity: `${line.lineal_metres}m`,
					unit_price: line.price_4_weeks,
					hire_fee: line.hire_fee,
					additional_travel: line.additional_travel,
			  }))
			: []),

		...(quote_mobile_lines
			? quote_mobile_lines?.map((line) => ({
					id: `mobile_line_${line.id}`,
					type: 'Mobile Scaffolding',
					description: line.description,
					quantity: `${line.width}m x ${line.length}m x ${line.height}m`,
					unit_price: line.price,
					hire_fee: line.hire_fee,
					additional_travel: line.additional_travel,
			  }))
			: []),
	]

	return (
		<div className="w-full mx-auto mt-8">
			<TwoColumnDetails
				heading="Quote Details"
				isEditable={quote.status === 'Approved' ? false : true}
				editBtn="Edit Quote"
				editLink={{
					to: AppRoutes.privateRoutes.QuotesEdit.replace(':id', id || ''),
				}}>
				<Section title="Quote #" content={quote?.quote_num} />
				<Section title="Client" content={quote?.clientData?.client_name} />
				<Section title="Quote Type" content={quote?.quote_type} />
				<Section title="Variation Job" content="" />
				<Section title="Job Type" content={quote?.job_type} />
				<Section title="Scope of Work" content={quote?.scope_of_work} />
				<Section title="Zones" content={quote?.max_zones} />
				<Section title="Street Address" content={quote?.street} />
				<Section title="Estimator" content={quote?.estimatorData?.staff_name} />
				<Section title="Status" content={quote?.status} />
			</TwoColumnDetails>

			<Table
				title="Quote Lines"
				isLoading={
					quoteLineDataLoading === undefined ? true : quoteLineDataLoading
				}
				columns={[
					{ header: 'Type', field: 'type' },
					{ header: 'Description', field: 'description' },
					{ header: 'Quantity', field: 'quantity' },
					{
						header: 'Additional Travel',
						field: 'additional_travel',
						body: (row) => numberFormat.format(row.additional_travel || 0),
					},
					{
						header: 'Unit Price',
						field: 'unit_price',
						body: (row) => numberFormat.format(row.unit_price || 0),
					},
					{
						header: 'Total Price',
						field: 'total_cost',
						body: (row) =>
							numberFormat.format(row.unit_price + row.additional_travel || 0),
					},
					{
						header: 'Hire Fee (P/U)',
						field: 'hire_fee',
						body: (row) => numberFormat.format(row.hire_fee || 0),
					},
				]}
				data={quote_line_data}
				DisableHeader
				disablePaginator
			/>

			<Table
				title="Additional Items"
				isLoading={
					quote_addons_loading === undefined ? true : quote_addons_loading
				}
				columns={[
					{ header: 'Type', field: 'type' },
					{ header: 'Description', field: 'description' },
					{ header: 'Duration / Quantity', field: 'duration_quantity' },
					{
						header: 'Fixed Charge',
						field: 'fixed_charge',
						body: (row) => numberFormat.format(row.fixed_charge || 0),
					},
					{
						header: 'Total',
						field: 'total_cost',
						body: (row) => numberFormat.format(row.total_cost || 0),
					},
				]}
				data={quote_addons}
				DisableHeader
				disablePaginator
			/>
			<br />

			<div className="pl-7">
				<h2 className="text-lg leading-6 font-medium text-gray-900">
					Additional Conditions
				</h2>
				<div className="w-6/12 card">
					<TextArea
						handleBlur={() => null}
						handleChange={() => null}
						id="additional_conditions"
						rows={10}
						disabled={true}
						value={quote?.terms || ''}
					/>
				</div>
			</div>

			<Totals quote={quote} />
		</div>
	)
}

interface totalsProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	quote: any
}

function Totals({ quote }: totalsProps) {
	return (
		<div className="w-2/5 px-6 my-12">
			<h2 className="pl-4 text-lg leading-6 font-sm uppercase text-gray-700 my-4">
				Totals
			</h2>
			<dl className="py-6 space-y-6 px-4">
				<div className="flex items-center justify-between">
					<dt className="text-sm">Total Erect and Dismantle</dt>
					<dd className="text-sm font-medium text-gray-900">
						{numberFormat.format(quote?.erectDismantleTotal)}
					</dd>
				</div>
				<div className="flex items-center justify-between">
					<dt className="text-sm">Total Additional</dt>
					<dd className="text-sm font-medium text-gray-900">
						{numberFormat.format(quote?.additionalTotal)}
					</dd>
				</div>
				<div className="flex items-center justify-between">
					<dt className="text-sm">Total Weekly Amount</dt>
					<dd className="text-sm font-medium text-gray-900">
						{numberFormat.format(quote?.weekTotal)}
					</dd>
				</div>
				<div className="flex items-center justify-between border-t border-gray-200 pt-6">
					<dt className="text-base font-medium">Total Amount</dt>
					<dd className="text-base font-medium text-gray-900">
						{numberFormat.format(quote?.total)}
					</dd>
				</div>
			</dl>
		</div>
	)
}
