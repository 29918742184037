import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EditCrewsForm } from 'components/Crews'

export const EditCrewsFormPage = () => {
	const [openCrewForm, setOpenCrewForm] = useState(true)
	const navigate = useNavigate()
	const { id } = useParams()

	useEffect(() => {
		if (!openCrewForm) {
			navigate(-1)
		}
	}, [openCrewForm])

	console.log("TEST WTF")

	return (
		<EditCrewsForm
			formType="update"
			heading="Edit Crew"
			open={openCrewForm}
			setOpen={setOpenCrewForm}
			crew_id={id}
		/>
	)
}
