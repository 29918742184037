import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteZones,
	IRates,
	IQuoteLineHandlers,
	IQuoteStairVoidLine,
	IQuoteForm,
} from 'models'
import { LockClosedIcon, LockOpenIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog } from 'common'
import '../customStyle.css'
import { FormikProps } from 'formik'

interface QuoteLineProps {
	data: IQuoteStairVoidLine
	quoteLinesHandlers: IQuoteLineHandlers
	index: number
	formik: FormikProps<IQuoteForm>
}

export const QuoteStairVoidLine = ({
	data,
	index,
	quoteLinesHandlers,
	formik,
}: QuoteLineProps) => {
	const handleProductLineChange = (
		index: number,
		field: any,
		value: string | number | boolean
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	return (
		<tr>
			<td className="px-0.5 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<input
					id={`category${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.category || ''}
					onChange={(e) =>
						handleProductLineChange(index, 'category', e.target.value)
					}
					disabled
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`width${data?.id}`}
					type="number"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.width || 0}
					onChange={(e) =>
						handleProductLineChange(index, 'width', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`length${data?.id}`}
					type="number"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.length || 0}
					onChange={(e) =>
						handleProductLineChange(index, 'length', Number(e.target.value))
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`price_code${data?.id}`}
					type="text"
					className={`h-7 text-sm text-center rounded-md w-full border border-gray-100 focus:outline-none ${
						data?.price_code === 'Purple'
							? 'bg-purple-500 text-white'
							: data?.price_code === 'Green'
							? 'bg-green-500 text-white'
							: data?.price_code === 'Yellow'
							? 'bg-yellow-500 text-black'
							: data?.price_code === 'Pink'
							? 'bg-pink-500 text-white'
							: data?.price_code === 'Blue'
							? 'bg-blue-500 text-white'
							: data?.price_code === 'Orange'
							? 'bg-orange-500 text-black'
							: 'bg-gray-400 text-gray-600'
					}`}
					value={data?.price_code || ''}
					disabled
					onChange={(e) =>
						handleProductLineChange(index, 'price_code', e.target.value)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-16">
				<input
					id={`price${data?.id}`}
					type="number"
					className={`h-7 text-sm text-center rounded-md w-full border border-gray-100 focus:outline-none ${
						data?.price_code === 'Purple'
							? 'bg-purple-500 text-white'
							: data?.price_code === 'Green'
							? 'bg-green-500 text-white'
							: data?.price_code === 'Yellow'
							? 'bg-yellow-500 text-black'
							: data?.price_code === 'Pink'
							? 'bg-pink-500 text-white'
							: data?.price_code === 'Blue'
							? 'bg-blue-500 text-white'
							: data?.price_code === 'Orange'
							? 'bg-orange-500 text-black'
							: 'bg-gray-400 text-gray-600'
					}`}
					value={data?.price || ''}
					disabled
					onChange={(e) =>
						handleProductLineChange(index, 'price', e.target.value)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`additional_travel${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={`$${data?.additional_travel?.toFixed(2) || ''}`}
					disabled
					onChange={(e) =>
						handleProductLineChange(index, 'additional_travel', e.target.value)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm text-center bg-white border border-gray-100 whitespace-nowrap w-32">
			<div className="flex justify-start items-center">
				{!formik.values.quote_stair_lines?.[index]
					?.override_price && (
					<LockClosedIcon
						onClick={() => {
							handleProductLineChange(index, 'override_price', true)
						}}
						className="flex-shrink-0 h-4 w-4 text-red-500 mr-2"
					/>
				)}
				{formik.values.quote_stair_lines?.[index]
					?.override_price && (
					<LockOpenIcon
						onClick={() => {
							handleProductLineChange(index, 'override_price', false)
						}}
						className="flex-shrink-0 h-4 w-4 text-green-500 mr-2"
					/>
				)}
				<input
					id={`price_16_weeks${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.price_16_weeks || ''}
					disabled={
						!formik.values.quote_stair_lines?.[index]
							?.override_price
					}
					onChange={(e) =>
						handleProductLineChange(index, 'price_16_weeks', e.target.value)
					}
				/>
				</div>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`hire_fee${data?.id}`}
					type="text"
					className="h-7 text-sm text-center rounded-md w-full border border-gray-100 text-gray-600 focus:outline-none"
					value={data?.hire_fee || 0}
					disabled
					onChange={(e) =>
						handleProductLineChange(index, 'hire_fee', e.target.value)
					}
				/>
			</td>
		</tr>
	)
}
