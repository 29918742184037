import { AppRoutes } from 'config'
import { Container, TaskContainer } from './styledComponents'
import { Link, Location } from 'react-router-dom'
import { jobTaskColors } from 'models'

interface EventProps {
	type: string
	job_id: number
	teamLeader: string
	crew_vehicle: string
	job_type: string
	site: string
	tasks: any[]
}

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any
	location: Location
}

const EventView = ({ event, location }: IProps) => {	
	
	
	const eventProp: EventProps = event.extendedProps
	const { publicId: visitId } = event._def

	const styles = () => ({
		color: '#ffffff',
		backgroundColor: jobTaskColors[eventProp?.tasks?.[0].type as keyof typeof jobTaskColors] || '#A0522D',
	})

	console.log(eventProp)

	return (
		<Link
			to={{
				pathname: AppRoutes.privateRoutes.visitsEdit.replace(
					':id',
					visitId.toString()
				),
			}}
			state={{ background: location, name: 'editVisit' }}>
			<Container
				padding="0.5px"
				fontSize="0.8rem"
				color={styles().color}
				className=' shadow-md'
				backgroundColor={styles().backgroundColor}>
				<TaskContainer>
					<b>Job #:</b> {eventProp.job_id} - {eventProp.site}
				</TaskContainer>
				<TaskContainer>
					<b>Visit Type:</b> {eventProp.type}
				</TaskContainer>
				<TaskContainer>
					<b>Task Type:</b> {eventProp?.tasks?.[0].type}
				</TaskContainer>
				<TaskContainer>
					<b>Team Leader:</b> {eventProp.teamLeader}
				</TaskContainer>
				<TaskContainer>
					<b>Vehicle Name:</b> {eventProp.crew_vehicle}
				</TaskContainer>
			</Container>
		</Link>
	)
}

export default EventView
