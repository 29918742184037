export function convertToVoidCategory(width: number, length: number): string {
	// Define the data in ConfigVoidStepsWidth and ConfigVoidStepsLength
	width = width / 1000
	length = length / 1000
	const configVoidStepsWidth = [0.0, 1.2, 1.4, 1.8, 2.4, 3.0, 3.5, 4.0]
	const configVoidStepsLength = [0.0, 3.5, 5.0, 6.0, 7.0]

	// Limit the width and length values to a maximum of 4 and 7, respectively
	width = Math.min(width, 4)
	length = Math.min(length, 7)

	// Find the nearest rounded-up values for width and length
	const roundedWidth = Math.min(
		...configVoidStepsWidth.filter((val) => val >= width)
	)
	const roundedLength = Math.min(
		...configVoidStepsLength.filter((val) => val >= length)
	)

	// Check if the rounded values exist in the arrays
	if (roundedWidth !== -1 && roundedLength !== -1) {
		// If both values exist in the arrays, perform the lookup
		return formatVoidCategoryOutput(roundedWidth, roundedLength)
	} else {
		// Handle the case where the values do not exist in the arrays
		return 'Not found'
	}
}

export const calculateScaffoldingCost = (width: number, height: number) => {
	// Define base costs based on height ranges
	let baseCost = { hire: 0, erection: 0, total: 0 }
	if (width === 0.7 || width === 1.2) {
		if (height === 1 || height === 2.5) {
			baseCost = { hire: 100, erection: 65, total: 390 }
		} else if (height === 3) {
			baseCost = { hire: 110, erection: 75, total: 420 }
		} else if (height === 3.5) {
			baseCost = { hire: 125, erection: 80, total: 445 }
		} else if (height === 4) {
			baseCost = { hire: 140, erection: 90, total: 480 }
		} else if (height === 4.5) {
			baseCost = { hire: 160, erection: 95, total: 510 }
		} else if (height === 5) {
			baseCost = { hire: 175, erection: 110, total: 555 }
		} else if (height === 5.5) {
			baseCost = { hire: 195, erection: 120, total: 595 }
		} else if (height === 6) {
			baseCost = { hire: 210, erection: 130, total: 630 }
		} else if (height === 6.5) {
			baseCost = { hire: 220, erection: 160, total: 700 }
		} else if (height === 7) {
			baseCost = { hire: 230, erection: 230, total: 850 }
		} else if (height === 10) {
			baseCost = { hire: 300, erection: 370, total: 1200 }
		}
	}

	if (width === 1.2) {
		baseCost.total = baseCost.total + baseCost.hire
		baseCost.hire *= 2
	}

	if (width === 1.8 || width === 2.4) {
		if (height === 2.5) {
			baseCost = { hire: 110, erection: 80, total: 430 }
		} else if (height === 3) {
			baseCost = { hire: 120, erection: 85, total: 450 }
		} else if (height === 3.5) {
			baseCost = { hire: 135, erection: 100, total: 495 }
		} else if (height === 4) {
			baseCost = { hire: 150, erection: 105, total: 520 }
		} else if (height === 4.5) {
			baseCost = { hire: 170, erection: 110, total: 550 }
		} else if (height === 5) {
			baseCost = { hire: 185, erection: 130, total: 605 }
		} else if (height === 5.5) {
			baseCost = { hire: 210, erection: 145, total: 660 }
		} else if (height === 6) {
			baseCost = { hire: 220, erection: 155, total: 690 }
		}
	}
	if (width === 2.4) {
		baseCost.total = baseCost.total + baseCost.hire
		baseCost.hire *= 2
	}

	// console.log('baseCostbaseCostbaseCost', baseCost)

	return baseCost
}

function formatVoidCategoryOutput(width: number, length: number) {
	return `${width?.toFixed(2)},${length?.toFixed(2)}`
}

export function findPriceCode(voidCategory: string): string {
	const priceCodeDict: { [key: string]: string } = {
		'0.00,0.00': 'Green',
		'1.20,3.50': 'Green',
		'1.20,5.00': 'Green',
		'1.20,6.00': 'Green',
		'1.20,7.00': 'Green',
		'1.40,3.50': 'Green',
		'1.40,5.00': 'Yellow',
		'1.40,6.00': 'Pink',
		'1.40,7.00': 'Pink',
		'1.80,3.50': 'Green',
		'1.80,5.00': 'Yellow',
		'1.80,6.00': 'Pink',
		'1.80,7.00': 'Blue',
		'2.40,3.50': 'Green',
		'2.40,5.00': 'Pink',
		'2.40,6.00': 'Pink',
		'2.40,7.00': 'Blue',
		'3.00,3.50': 'Yellow',
		'3.00,5.00': 'Orange',
		'3.00,6.00': 'Orange',
		'3.00,7.00': 'Purple',
		'3.50,3.50': 'Orange',
		'3.50,5.00': 'Blue',
		'3.50,6.00': 'Purple',
		'3.50,7.00': 'Purple',
		'4.00,3.50': 'Blue',
		'4.00,5.00': 'Blue',
		'4.00,6.00': 'Purple',
		'4.00,7.00': 'Purple',
	}
	return priceCodeDict[voidCategory] || 'Not found'
}

interface Pricing {
	initial: number
	weekly: number
}

const priceCodeToPrices: Record<string, Pricing> = {
	Green: { initial: 495, weekly: 30 },
	Yellow: { initial: 600, weekly: 40 },
	Pink: { initial: 700, weekly: 50 },
	Orange: { initial: 900, weekly: 70 },
	Blue: { initial: 1200, weekly: 85 },
	Purple: { initial: 1800, weekly: 95 },
}

export function getPriceForCode(priceCode: string): Pricing | undefined {
	return priceCodeToPrices[priceCode]
}
