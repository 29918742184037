import { pdf } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteAdditionalLines,
	DTOQuoteEntranceLine,
	DTOQuoteFirstFloorEdgeLine,
	DTOQuoteHangOnPlatformLine,
	DTOQuoteMobileLine,
	DTOQuoteRoofRailLine,
	DTOQuoteSingleDeckLine,
	DTOQuoteStairLine,
} from 'models'
import { QuotePDFDocument } from './Output'

interface QuotePDFBlobProps {
	quote_data: QuoteData
	quote_lines: {
		quote_entrance_void_lines: DTOQuoteEntranceLine[]
		quote_stair_lines: DTOQuoteStairLine[]
		quote_hang_on_platform_lines: DTOQuoteHangOnPlatformLine[]
		quote_first_floor_edge_lines: DTOQuoteFirstFloorEdgeLine[]
		quote_roof_rail_lines: DTOQuoteRoofRailLine[]
		quote_single_deck_lines: DTOQuoteSingleDeckLine[]
		quote_mobile_lines: DTOQuoteMobileLine[]
	}
	quote_addons: IQuoteAdditionalLines[]
}

export const MakeBlobQuote = async ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFBlobProps) => {
	const newBlob = await pdf(
		QuotePDFDocument({ quote_data, quote_lines, quote_addons })
	).toBlob()

	return newBlob
}
