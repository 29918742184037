import { PDFViewer } from '@react-pdf/renderer'
import { QuotePDFDocument } from './Output'
import {
	QuoteData,
	IQuoteAdditionalLines,
	IQuoteEntranceVoidLine,
	IQuoteFirstFloorEdgeLine,
	IQuoteHangOnPlatformLine,
	IQuoteMobileLine,
	IQuoteRoofRailLine,
	IQuoteSingleDeckScaffoldLine,
	IQuoteStairVoidLine,
	DTOQuoteEntranceLine,
	DTOQuoteStairLine,
	DTOQuoteHangOnPlatformLine,
	DTOQuoteFirstFloorEdgeLine,
	DTOQuoteRoofRailLine,
	DTOQuoteSingleDeckLine,
	DTOQuoteMobileLine,
} from 'models'

interface QuotePDFViewerProps {
	quote_data: QuoteData
	quote_lines: {
		quote_entrance_void_lines: DTOQuoteEntranceLine[]
		quote_stair_lines: DTOQuoteStairLine[]
		quote_hang_on_platform_lines: DTOQuoteHangOnPlatformLine[]
		quote_first_floor_edge_lines: DTOQuoteFirstFloorEdgeLine[]
		quote_roof_rail_lines: DTOQuoteRoofRailLine[]
		quote_single_deck_lines: DTOQuoteSingleDeckLine[]
		quote_mobile_lines: DTOQuoteMobileLine[]
	}
	quote_addons: IQuoteAdditionalLines[]
}

export const QuotePDFViewer = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFViewerProps) => {
	// console.log(quote_lines)
	return (
		<>
			<PDFViewer width="100%" height="1000">
				<QuotePDFDocument
					quote_data={quote_data}
					quote_lines={quote_lines}
					quote_addons={quote_addons}
				/>
			</PDFViewer>
		</>
	)
}
