const quote_lines = [
{
	id: 'strategy',
	heading: 'Strategy',
},
{
	id: 'total',
	heading: 'Erect & Dismantle',
},
{
	id: 'fee',
	heading: 'Hire Fee',
},
]

const quote_addons = [
{
	id: 'additionalDesc',
	heading: 'Description',
},
{
	id: 'additionalTotal',
	heading: 'Additional Costs',
},
]

export const columns = {
quote_lines,
quote_addons,
}

export enum ColumnTypes {
quote_lines = 'quote_lines',
quote_addons = 'quote_addons',
}
