export const OptionsForDropdownString = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	arr: any[] | undefined,
	val: string | number,
	label: string | number
) => {
	if (!arr) {
		return []
	}

	return arr.map((item) => {
		return {
			value: String(item[val]),
			label: item[label],
		}
	})
}

export const OptionsForDropdown = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	arr: any[] | undefined,
	val: string | number,
	label: string | number
) => {
	if (!arr) {
		return []
	}
	return arr.map((item) => {
		if(item.crew_leader && label == 'crew_leader')
		{
			return {
				value: item[val],
				label: item?.crew_leader?.staff_name,
			}
		}
		if(item.vehicle && label == 'vehicle')
		{
			return {
				value: item[val],
				label: item?.vehicle?.CodeName,
			}
		}
		return {
			value: item[val],
			label: item[label],
		}
	})
}

type Option = {
	value: string | number
	label: string
}

export const OptionsForDropdownNumber = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	arr: any[] | undefined,
	val: number | string,
	label: string | number
): Option[] => {
	if (!arr) {
		return []
	}

	return arr.map((item) => {
		return {
			value: Number(item[val]),
			label: item[label],
		}
	})
}

export const OptionsForDropdownFilter = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	arr: any[] | undefined,
	val: string | number,
	label: string | number | string[],
	filterKey: string | number,
	filterValue: string | number
) => {
	if (!arr) {
		return []
	}

	return arr
		.filter((item) => String(item[filterKey]) === String(filterValue))
		.map((item) => {
			if (Array.isArray(label)) {
				return {
					value: item[val],
					label: label.map((labelItem) => item[labelItem]).join(' - '),
				}
			}
			return {
				value: item[val],
				label: item[label],
			}
		})
}
