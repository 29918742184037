export const job_status_options = [
	{
		value: 'Pending Handover',
		label: 'Pending Handover',
	},
	{
		value: 'In Progress',
		label: 'In Progress',
	},
	{
		value: 'Completed',
		label: 'Completed',
	},
	{
		value: 'Signed Off',
		label: 'Signed Off',
	},
]

export const job_status_options_Edit = [
	{
		value: 'In Progress',
		label: 'In Progress',
	},
	{
		value: 'Completed',
		label: 'Completed',
	},
	{
		value: 'Signed Off',
		label: 'Signed Off',
	},
]

export const jobTaskColors = {
	'Entrance Void Protection': '#2ECC71', // Lighter Sea Green
	'Stair Void Protection': '#FF6347', // Tomato
	'Hang On Platform': '#FFD700', // Gold
	'First Floor Edge Protection': '#00BFFF', // Deep Sky Blue
	'Roof Rail': '#FF69B4', // Hot Pink
	'Mobile Platform': '#9370DB', // Medium Purple
	'Other': '#A0522D',
}
