import { IQuoteAdditionalLines } from 'models'

export const totalsFn = {
	calculateAdditionalTotal: (data: IQuoteAdditionalLines[]): number => {
		return data.reduce((acc, curr) => acc + Number(curr.total_cost), 0)
	},
	calculateWeekTotal: (lines: any[]): number => {
		const linesTotal = lines.reduce(
			(acc, curr) => acc + Number(curr.hire_fee),
			0
		)
		return linesTotal
	},
	calculateTotals: (
		edTotal: number,
		addOnsTotal: number,
		weekTotal: number
	): number => {
		return Number(edTotal) + Number(addOnsTotal) + Number(weekTotal)
	},
}
