import { IQuoteMobileLine, IQuoteZones, IRates } from 'models'
import * as quoteHelpers from './quoteLineHelpersGeneric'

const initLine: IQuoteMobileLine = {
	id: null,
	category: 'Mobile Scaffolding',
	price: 0,
	length: 0,
	width: 0,
	height: 0,
	additional_travel: 0,
	hire_fee: 0,
	override_price: false,
}

const addNewQuoteLine = (
	quote_lines: IQuoteMobileLine[]
): IQuoteMobileLine[] => {
	quote_lines.push(initLine)
	return quote_lines
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteMobileLine[]
): IQuoteMobileLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteMobileLine[],
	field: string,
	value: string | number | boolean
): IQuoteMobileLine[] => {
	const newQuoteLines: IQuoteMobileLine[] = quote_lines.map((line, i) => {
		if (i === index) {
			const newLine = {
				...line,
				[field]: value,
			}
			return newLine
		}
		return line
	})

	return newQuoteLines
}

const calculateQuoteLines = (
	distance_gpo: string | null,
	quote_lines: IQuoteMobileLine[]
): IQuoteMobileLine[] => {
	const newQuoteLines = quote_lines.map((line) => {
		// console.log(line)
		let priceCode = ''
		let additionalTravel = 0
		const travelCost = Math.max(
			0,
			Number(((Number(distance_gpo) * 4 - 200) * 1.5)?.toFixed(2))
		)
		let priceCodePrice = 0
		let initialPrice = 0
		let hire = 0
		if (line.width && line.height) {
			const calculateThePrice = quoteHelpers.calculateScaffoldingCost(
				line.width,
				line.height
			)
			initialPrice = calculateThePrice.total || 0
			priceCodePrice = initialPrice
			hire = calculateThePrice.hire
		} else {
			priceCode = '...'
		}
		if (distance_gpo) {
			if (Number(distance_gpo) > 50) {
				additionalTravel = travelCost
			}
		}
		const calculatedLine = {
			...line,
			additional_travel: additionalTravel,
			price: line.override_price == false ? initialPrice : line.price,
			hire_fee: hire,
		}
		return calculatedLine
	})
	return newQuoteLines
}

export const quoteMobileScaffoldingLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateQuoteLines,
}
