import { Table } from 'common'
import columns from './Columns'
import { useNotesJobs } from 'services/notes'
import { useAppFilesJobID } from 'services/appfiles'

interface JobsTableProps {
	id: string | number
	setShowNotesForm: (open: boolean) => void
	setNoteID: (id: string) => void
}

export const JobsTable = ({
	id,
	setShowNotesForm,
	setNoteID,
}: JobsTableProps) => {
	const { data: jobNotes, isLoading: jobNotesLoading } = useNotesJobs(id)
	const { data: jobFiles, isLoading: jobFilesLoading } = useAppFilesJobID(id)
	const jobNotesMap = jobNotes?.map((note: any) => {
		return {
			createdDate: note.createdDate,
			fileName: note.fileName,
			fileType: note.fileType,
			id: `NOTE_${note.id}`,
			fileDescription: note.fileDescription,
			notes: note.notes,
			fileUrl: note.fileUrl,
			editable: true,
			deletable: true,
		}
	}) || []
	const jobFilesMap = jobFiles?.map((file: any) => {
		return {
			createdDate: new Date(file.created_at),
			fileName: file.file_name,
			fileType: file.file_type,
			id: `FILE_${file.id}`,
			fileDescription: '',
			notes: file.notes,
			fileUrl: file.link,
			editable: false,
			deletable: false,
		}
	}) || []

	const data = [...jobNotesMap, ...jobFilesMap]

	return (
		<div>
			<Table
				columns={columns({ setShowNotesForm, setNoteID })}
				data={data}
				isLoading={jobNotesLoading || jobFilesLoading}
				title="Job Notes & Files"
				setShowNotesForm={setShowNotesForm}
			/>
		</div>
	)
}
