import { Button, Checkbox, Spinner, Tabs, TextArea } from 'common'
import { QuoteServices, JobsServices } from 'services'
import {
	ZonesForm,
	RatesForm,
	QuoteAdditionalLines,
	Totals,
	QuoteStairVoidLinesForm,
	QuoteHangOnPlatformLinesForm,
	QuoteFirstFloorEdgeLinesForm,
	QuoteRoofRailLinesForm,
	QuoteSingleDeckLinesForm,
	QuoteEntranceVoidLinesForm,
	QuoteMobileScaffoldingLinesForm,
} from '../FormComponents'
import { useState } from 'react'
import { GeneralInfo } from '../FormComponents/GeneralInfo'
import { RatesAdmin } from '../FormComponents/Rates/RatesAdmin'
import SiteFiles from '../FormComponents/SiteFiles/SiteFiles'
import {
	IQuoteEntranceVoidLine,
	IQuoteFirstFloorEdgeLine,
	IQuoteHangOnPlatformLine,
	IQuoteMobileLine,
	IQuoteRoofRailLine,
	IQuoteSingleDeckScaffoldLine,
	IQuoteStairVoidLine,
} from 'models'

interface FormProps {
	quoteID?: number
}

export const Form = ({ quoteID }: FormProps) => {
	const [tabIndex, setTabIndex] = useState(1)

	const { data: quote_data, isLoading: quote_loading } =
		QuoteServices.useQuoteById(quoteID)

	// const { data: quote_lines, isLoading: quote_lines_loading } =
	// 	QuoteServices.useQuoteLinesById(quoteID)

	const {
		data: quote_entrance_lines,
		isLoading: quote_entrance_lines_loading,
	} = QuoteServices.useQuoteEntranceLinesByQuoteID(quoteID)
	// console.log('usequotentrnancelines')
	const { data: quote_stair_lines, isLoading: quote_stair_lines_loading } =
		QuoteServices.useQuoteStairLinesByQuoteID(quoteID)
	const {
		data: quote_hang_on_platform_lines,
		isLoading: quote_hang_on_platform_lines_loading,
	} = QuoteServices.useQuoteHangOnPlatformLinesByQuoteID(quoteID)
	const {
		data: quote_first_floor_edge_lines,
		isLoading: quote_first_floor_edge_lines_loading,
	} = QuoteServices.useQuoteFirstFloorEdgeLinesByQuoteID(quoteID)
	const {
		data: quote_roof_rail_lines,
		isLoading: quote_roof_rail_lines_loading,
	} = QuoteServices.useQuoteRoofRailLinesByQuoteID(quoteID)
	const {
		data: quote_single_deck_lines,
		isLoading: quote_single_deck_lines_loading,
	} = QuoteServices.useQuoteSingleDeckLinesByQuoteID(quoteID)
	const { data: quote_mobile_lines, isLoading: quote_mobile_lines_loading } =
		QuoteServices.useQuoteMobileLinesByQuoteID(quoteID)

	const { data: quote_addons, isLoading: quote_addons_loading } =
		QuoteServices.useQuoteAddonsById(quoteID)

	const { data: quote_zones, isLoading: quote_zones_loading } =
		QuoteServices.useQuoteZonesById(quoteID)

	const { data: quote_rates, isLoading: quote_rates_loading } =
		QuoteServices.useQuoteRatesById(quoteID)

	const { data: jobs_data, isLoading: jobs_loading } = JobsServices.useJobs()

	const {
		formik,
		quoteEntranceVoidLinesHandlers,
		quoteStairVoidLinesHandlers,
		quoteFirstFloorEdgeHandlers,
		quoteHangOnPlatformHandlers,
		quoteRoofRailHandlers,
		quoteSingleDeckLinesHandlers,
		quoteAdditionalLinesHandlers,
		quoteMobileScaffoldingLinesHandlers,
		ratesHandlers,
		tableOptions,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	} = QuoteServices.useFormHandler({
		quote_id: quoteID,
		quote_data,
		quote_entrance_lines,
		quote_stair_lines,
		quote_hang_on_platform_lines,
		quote_first_floor_edge_lines,
		quote_roof_rail_lines,
		quote_single_deck_lines,
		quote_mobile_lines,
		quote_addons,
		quote_rates,
		quote_zones,
	})

	if (
		quote_loading ||
		quote_entrance_lines_loading ||
		quote_stair_lines_loading ||
		quote_hang_on_platform_lines_loading ||
		quote_first_floor_edge_lines_loading ||
		quote_roof_rail_lines_loading ||
		quote_single_deck_lines_loading ||
		quote_mobile_lines_loading ||
		quote_addons_loading ||
		quote_zones_loading ||
		quote_rates_loading ||
		jobs_loading
	) {
		return <Spinner />
	}
	// console.log(formik.values.quote_entrance_void_lines.length)
	// console.log(formik.values.quote_first_floor_edge_lines.length)
	// console.log(formik.values.quote_hang_on_platform_lines.length)
	// console.log(formik.values.quote_mobile_lines.length)
	// console.log(formik.values.quote_roof_rail_lines.length)
	// console.log(formik.values.quote_single_deck_lines.length)
	// console.log(formik.values.quote_stair_lines.length)

	if (formik.values.quote_entrance_void_lines.length === 0) {
		quoteEntranceVoidLinesHandlers.addNewQuoteLine()
	}
	if (formik.values.quote_first_floor_edge_lines.length === 0) {
		quoteFirstFloorEdgeHandlers.addNewQuoteLine()
	}
	if (formik.values.quote_hang_on_platform_lines.length === 0) {
		quoteHangOnPlatformHandlers.addNewQuoteLine()
	}
	if (formik.values.quote_mobile_lines.length === 0) {
		quoteMobileScaffoldingLinesHandlers.addNewQuoteLine()
	}
	if (formik.values.quote_roof_rail_lines.length === 0) {
		quoteRoofRailHandlers.addNewQuoteLine()
	}
	if (formik.values.quote_single_deck_lines.length === 0) {
		quoteSingleDeckLinesHandlers.addNewQuoteLine()
	}
	if (formik.values.quote_stair_lines.length === 0) {
		quoteStairVoidLinesHandlers.addNewQuoteLine()
	}

	return (
		<>
			<div className="mx-4 mb-10">
				<div className="w-full">
					<div className="flex">
						<div className="lg:w-4/6 md:w-full sm:w-full">
							<GeneralInfo formik={formik} jobs_data={jobs_data} />
						</div>
						<div className="w-full">
							<div className="w-4/6 mx-auto">
								<div className="flex justify-start mb-4">
									<Tabs
										tabIndex={tabIndex}
										setTabIndex={setTabIndex}
										tabs={itemsTabs}
									/>
								</div>
								{/* {tabIndex === 0 && (
									<ZonesForm
										zones={formik.values.zones}
										zonesHandlers={zonesHandlers}
										errors={formik.errors}
									/>
								)} */}
								{tabIndex === 1 && (
									<RatesForm
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
								{tabIndex === 2 && (
									<RatesAdmin
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="mb-10">
						<Checkbox
							title="Table Options"
							options={tableOptions}
							values={
								typeof formik?.values?.table_options === 'string'
									? JSON.parse(formik?.values?.table_options)
									: formik?.values?.table_options
							}
							id="table_options"
							onChange={(id: string, values: string[]) => {
								formik.setFieldValue(id, JSON.stringify(values))
							}}
							orientation="horizontal"
						/>
					</div>
					<>
						{formik.values.table_options?.includes(
							'is_entrance_void_visible'
						) ? (
							<QuoteEntranceVoidLinesForm
								quoteLines={formik.values.quote_entrance_void_lines}
								quoteLinesHandlers={quoteEntranceVoidLinesHandlers}
								formik={formik}
							/>
						) : null}

						{formik.values.table_options?.includes('is_stair_void_visible') ? (
							<QuoteStairVoidLinesForm
								quoteLines={formik.values.quote_stair_lines}
								quoteLinesHandlers={quoteStairVoidLinesHandlers}
								formik={formik}
							/>
						) : null}
						{formik.values.table_options?.includes(
							'is_hang_on_platform_visible'
						) ? (
							<QuoteHangOnPlatformLinesForm
								quoteLines={formik.values.quote_hang_on_platform_lines}
								quoteLinesHandlers={quoteHangOnPlatformHandlers}
								formik={formik}
							/>
						) : null}
						{formik.values.table_options?.includes(
							'is_first_floor_edge_visible'
						) ? (
							<QuoteFirstFloorEdgeLinesForm
								quoteLines={formik.values.quote_first_floor_edge_lines}
								quoteLinesHandlers={quoteFirstFloorEdgeHandlers}
								formik={formik}
							/>
						) : null}
						{formik.values.table_options?.includes('is_roof_rail_visible') ? (
							<QuoteRoofRailLinesForm
								quoteLines={formik.values.quote_roof_rail_lines}
								quoteLinesHandlers={quoteRoofRailHandlers}
								formik={formik}
							/>
						) : null}
						{formik.values.table_options?.includes('is_single_deck_visible') ? (
							<QuoteSingleDeckLinesForm
								quoteLines={formik.values.quote_single_deck_lines}
								quoteLinesHandlers={quoteSingleDeckLinesHandlers}
								formik={formik}
							/>
						) : null}

						{formik.values.table_options?.includes(
							'is_mobile_scaffolding_visible'
						) ? (
							<QuoteMobileScaffoldingLinesForm
								quoteLines={formik.values.quote_mobile_lines}
								quoteLinesHandlers={quoteMobileScaffoldingLinesHandlers}
								formik={formik}
							/>
						) : null}

						<QuoteAdditionalLines
							rates={formik.values.rates}
							additionalLines={formik.values.quote_additional_lines}
							additionalLinesHandlers={quoteAdditionalLinesHandlers}
						/>

						<SiteFiles
							setFieldValue={formik.setFieldValue}
							values={formik.values}
						/>

						<div className="w-3/6">
							<TextArea
								title="Terms"
								id="terms"
								placeholder="Terms"
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
								type="text"
								value={formik.values.terms}
								error={formik.errors.terms}
								rows={10}
							/>
						</div>

						<Totals
							erectDismantleTotal={formik.values.erectDismantleTotal}
							additionalTotal={formik.values.additionalTotal}
							weekTotal={formik.values.weekTotal}
							total={formik.values.total}
						/>

						{quoteID && (
							<div className="pl-2 mt-6 flex space-x-4">
								<Button
									onClick={() => {
										setBackToMainTable(true)
										formik.handleSubmit()
									}}
									type="submit"
									form="quoteForm"
									//isLoading={quoteCreating}
									size="sm"
									variant={'primary'}>
									Save & Exit
								</Button>
								<Button
									onClick={() => formik.handleSubmit()}
									type="submit"
									form="quoteForm"
									size="sm"
									variant={'primary'}>
									Save & View
								</Button>
							</div>
						)}
						{!quoteID && (
							<div className="pl-2 mt-6">
								<Button
									onClick={() => formik.handleSubmit()}
									type="submit"
									form="quoteForm"
									size="sm"
									variant={'primary'}
									disabled={formik.isSubmitting}>
									<div className='flex flex-row justify-center content-center items-center'>
										{formik.isSubmitting && <div className='w-4 h-4 bg-slate-100 rounded-full mr-4'><Spinner /></div>}
									Create Quote
									</div>
								</Button>
							</div>
						)}
					</>
				</div>
			</div>
		</>
	)
}
