import { Document, Page, Text, View, Image } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteAdditionalLines,
	DTOQuoteEntranceLine,
	DTOQuoteFirstFloorEdgeLine,
	DTOQuoteHangOnPlatformLine,
	DTOQuoteMobileLine,
	DTOQuoteRoofRailLine,
	DTOQuoteSingleDeckLine,
	DTOQuoteStairLine,
} from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import { date } from 'yup'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: {
		quote_entrance_void_lines: DTOQuoteEntranceLine[]
		quote_stair_lines: DTOQuoteStairLine[]
		quote_hang_on_platform_lines: DTOQuoteHangOnPlatformLine[]
		quote_first_floor_edge_lines: DTOQuoteFirstFloorEdgeLine[]
		quote_roof_rail_lines: DTOQuoteRoofRailLine[]
		quote_single_deck_lines: DTOQuoteSingleDeckLine[]
		quote_mobile_lines: DTOQuoteMobileLine[]
	}
	quote_addons: IQuoteAdditionalLines[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFDocumentProps) => {
	type QuoteDataLine = {
		strategy: string
		description: string
		hire_description: string
		quantity: string,
		total: number
		fee: number
	}
	// console.log(quote_lines)

	const quote_line_data: QuoteDataLine[] = [
		...(quote_lines.quote_entrance_void_lines
			? quote_lines.quote_entrance_void_lines?.map((line) => ({
					strategy: 'Entrance Void',
					description: line.description,
					travel: line.additional_travel,
					quantity: 'Cost incl. initial hire, transport',
					hire_description: `Hire fee after initial 16 weeks`,
					total: line.price_16_weeks + line.additional_travel,
					fee: line.hire_fee,
			  }))
			: []),

		...(quote_lines.quote_stair_lines
			? quote_lines.quote_stair_lines?.map((line) => ({
					strategy: 'Stair Void',
					description: line.description,
					travel: line.additional_travel,
					quantity: 'Cost incl. initial hire, transport',
					hire_description: `Hire fee after initial 16 weeks`,
					total: line.price_16_weeks + line.additional_travel,
					fee: line.hire_fee,
			  }))
			: []),

		...(quote_lines.quote_hang_on_platform_lines
			? quote_lines.quote_hang_on_platform_lines?.map((line) => ({
					strategy: 'Hang On Platform',
					description: line.description,
					travel: line.additional_travel,
					quantity: 'Cost incl. initial hire, transport',
					hire_description: `Hire fee after initial 2 weeks`,
					total: line.price_2_weeks + line.additional_travel,
					fee: line.hire_fee,
			  }))
			: []),

		...(quote_lines.quote_first_floor_edge_lines
			? quote_lines.quote_first_floor_edge_lines?.map((line) => ({
					strategy: 'First Floor Edge',
					description: line.description,
					travel: line.additional_travel,
					quantity: 'Cost incl. initial hire, transport',
					hire_description: `Hire fee after initial 2 weeks`,
					total: line.price_2_weeks + line.additional_travel,
					fee: line.hire_fee,
			  }))
			: []),

		...(quote_lines.quote_roof_rail_lines
			? quote_lines.quote_roof_rail_lines?.map((line) => ({
					strategy: 'Roof Rail',
					description: line.description,
					travel: line.additional_travel,
					quantity: 'Cost incl. initial hire, transport',
					hire_description: `Hire fee after initial 2 weeks`,
					total: line.price_2_weeks + line.additional_travel,
					fee: line.hire_fee,
			  }))
			: []),

		...(quote_lines.quote_single_deck_lines
			? quote_lines.quote_single_deck_lines?.map((line) => ({
					strategy: 'Single Deck Scaffolding',
					description: line.description,
					travel: line.additional_travel,
					quantity: 'Cost incl. initial hire, transport',
					hire_description: `Hire fee after initial 4 weeks`,
					total: line.price_4_weeks + line.additional_travel,
					fee: line.hire_fee,
			  }))
			: []),

		...(quote_lines.quote_mobile_lines
			? quote_lines.quote_mobile_lines?.map((line) => ({
					strategy: 'Mobile Scaffolding',
					description: line.description,
					travel: line.additional_travel,
					quantity: 'Cost incl. initial hire, transport',
					hire_description: `Hire fee per week`,
					total: line.price + line.additional_travel,
					fee: line.hire_fee,
			  }))
			: []),
	]

	return (
		<Document title={`Elite Quotation - ${quote_data.quote_num}`}>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.quoteText}>Quotation</Text>
				<ClientInfo quote={quote_data} />
				<Description description={quote_data.scope_of_work} />
				<View wrap={false}>
					<Text style={styles.heading}>Quote Lines</Text>
					<Table type={ColumnTypes.quote_lines} data={quote_line_data} />
				</View>
				<View wrap={false}>
					<Text style={styles.heading}>Additional Items</Text>
					<Table type={ColumnTypes.quote_addons} data={quote_addons} />
				</View>

				<Text style={styles.subTextBoldRed}>All prices exclude GST</Text>

				<View wrap={false}>
					<Text style={styles.heading}>Additional Conditions</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						{quote_data.terms}
					</Text>
				</View>
			</Page>
			{(quote_data.file_1 != '' || quote_data.file_2 != '' || quote_data.file_3 != '' || quote_data.file_4 != '') && (
					<Page size="A4" style={styles.page_1}>
						{quote_data?.file_1 != null && quote_data?.file_1 != '' ? (
							<View key={'image_x_1'} style={styles.quadrant}>
								<Image src={quote_data?.file_1} style={styles.image} />
							</View>
						) : null}
						{quote_data?.file_2 != null && quote_data?.file_2 != '' ? (
							<View key={'image_x_2'} style={styles.quadrant}>
								<Image src={quote_data?.file_2} style={styles.image} />
							</View>
						) : null}
						{quote_data?.file_3 != null && quote_data?.file_3 != '' ? (
							<View key={'image_x_3'} style={styles.quadrant}>
								<Image src={quote_data?.file_3} style={styles.image} />
							</View>
						) : null}
						{quote_data?.file_4 != null && quote_data?.file_4 != '' ? (
							<View key={'image_x_4'} style={styles.quadrant}>
								<Image src={quote_data?.file_4} style={styles.image} />
							</View>
						) : null}
					</Page>
				)}
			<Page style={styles.page}>
				<Heading />
				<View style={styles.content}>
					<Text style={{ ...styles.endText, fontWeight: 'bold' }}>
						I ACCEPT THIS QUOTATION:
					</Text>
					<Text style={styles.endText}>
						(Please sign and fax, email etc, prior to commencement of work with
						your order number if applicable)
					</Text>
					<Text style={styles.endText}>
						NOTE: WORK ON THE ABOVE PROJECT WILL NOT COMMENCE UNTIL SIGNED COPY
						RECEIVED.
					</Text>
					<Text style={styles.largeSpace}>{'\n\n'}</Text>

					{/* Signature block */}
					<View style={styles.signatureBlock}>
						<Text style={styles.signatureText}>
							ORDER NUMBER: ____________________________________________
						</Text>
						<Text style={styles.signatureText}>
							SIGNED: ____________________________________________
						</Text>
						<Text style={styles.signatureText}>
							PRINT NAME: _____________________________________________
						</Text>
						<Text style={styles.signatureText}>
							DATE: ________/________/________/
						</Text>
					</View>
					<Text style={styles.largeSpace}>{'\n\n'}</Text>
					<Text style={styles.endText}>
						We thank you for the opportunity to be able to quote you on your
						project.
					</Text>
					<Text style={styles.endText}>Yours faithfully,</Text>
					<Text style={styles.endText}>ELITE SCAFFOLDING</Text>
					<Text style={styles.endText}>Ben Trusz</Text>
				</View>
			</Page>
		</Document>
	)
}
