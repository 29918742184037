import {
	IQuoteZones,
	IRates,
	IQuoteLineHandlers,
	columnsSingleDeckScaffoldingLines,
	IQuoteSingleDeckScaffoldLine,
	IQuoteForm,
} from 'models'
import { PlusIcon } from '@heroicons/react/24/solid'
import { QuoteSingleDeckLine } from './QuoteSingleDeckLine'
import { FormikProps } from 'formik'

interface QuoteLinesProps {
	quoteLines: IQuoteSingleDeckScaffoldLine[]
	quoteLinesHandlers: IQuoteLineHandlers
	formik: FormikProps<IQuoteForm>
}

export const QuoteSingleDeckLinesForm = ({
	quoteLines,
	quoteLinesHandlers,
	formik,
}: QuoteLinesProps) => {
	return (
		<>
			<div className="w-full px-2">
				<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
					Single Deck Scaffolding
				</h2>
				<table className="w-full divide-y table-fixed divide-gray-200">
					<thead className="bg-gray-100">
						<tr>
							{columnsSingleDeckScaffoldingLines.map((column) => (
								<th
									className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs"
									key={column.key}>
									{column.title}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{quoteLines.map(
							(quoteLine: IQuoteSingleDeckScaffoldLine, index: number) => (
								<QuoteSingleDeckLine
									key={quoteLine.id}
									data={quoteLine}
									index={index}
									quoteLinesHandlers={quoteLinesHandlers}
									formik={formik}
								/>
							)
						)}
					</tbody>
				</table>
				<div className="mt-6 mb-16">
					{!quoteLines.length ? (
						<button
							type="button"
							className="flex items-center"
							onClick={() => quoteLinesHandlers.addNewQuoteLine()}>
							<PlusIcon
								className="flex-shrink-0 h-5 w-5 text-green-500"
								aria-hidden="true"
							/>
							<span className="ml-2 text-sm">Add Item</span>
						</button>
					) : null}
				</div>
			</div>
		</>
	)
}
