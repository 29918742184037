import { IQuoteSingleDeckScaffoldLine, IQuoteZones, IRates } from 'models'

const initLine: IQuoteSingleDeckScaffoldLine = {
	id: null,
	strategy: 1,
	lineal_metres: 0,
	handrail_height: 0,
	sqm: 0,
	unit_price: 0,
	single_deck_price: 0,
	single_deck_price_weekly: 0,
	additional_travel: 0,
	price_4_weeks: 0,
	hire_fee: 0,
	override_price: false,
}

const addNewQuoteLine = (
	quote_lines: IQuoteSingleDeckScaffoldLine[]
): IQuoteSingleDeckScaffoldLine[] => {
	quote_lines.push(initLine)
	return calculateQuoteLines('', quote_lines)
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteSingleDeckScaffoldLine[]
): IQuoteSingleDeckScaffoldLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteSingleDeckScaffoldLine[],
	field: string,
	value: string | number | boolean
): IQuoteSingleDeckScaffoldLine[] => {
	const newQuoteLines: IQuoteSingleDeckScaffoldLine[] = quote_lines.map(
		(line, i) => {
			if (i === index) {
				const newLine = {
					...line,
					[field]: value,
				}
				return newLine
			}
			return line
		}
	)

	return newQuoteLines
}

const calculateQuoteLines = (
	distance_gpo: string | null,
	quote_lines: IQuoteSingleDeckScaffoldLine[]
): IQuoteSingleDeckScaffoldLine[] => {
	let additionalTravel = 0
	const travelCost = Math.max(
		0,
		Number(((Number(distance_gpo) * 4 - 200) * 2.5)?.toFixed(2))
	)
	if (distance_gpo) {
		//console.log(distance_gpo)

		if (Number(distance_gpo) > 50) {
			additionalTravel = travelCost
		}
	}

	const newQuoteLines = quote_lines.map((line) => {
		const low_price = 18
		const medium_price = 19
		const high_price = 20
		const unit_price =
			line.strategy === 1
				? low_price
				: line.strategy === 2
				? medium_price
				: line.strategy === 3
				? high_price
				: 0
		const minimum_weekly_hire = 250
		const weekly_rate = 0.9

		const normal_sqm = line.lineal_metres * line.handrail_height

		line.additional_travel = additionalTravel

		line.unit_price = unit_price
		line.sqm = normal_sqm
		line.single_deck_price = line.sqm * unit_price
		line.single_deck_price_weekly = normal_sqm * weekly_rate

		if (line.override_price == false) {
			line.price_4_weeks = line.single_deck_price
		}
		line.hire_fee = Math.max(line.single_deck_price_weekly, minimum_weekly_hire)

		return line
	})
	return newQuoteLines
}

export const quoteSingleDeckLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateQuoteLines,
}
