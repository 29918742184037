import { AppFilesServices } from 'services'
import { Table } from 'common'
import moment from 'moment'

export const MainFilesTable = () => {
	const { data, isLoading } = AppFilesServices.useAppFiles()

	const cols = [
		{
			header: 'Date Added',
			field: 'created_at',
			body: (row: { created_at: Date }) => {
				return row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : ''
			},
		},
		{
			header: 'File Name',
			field: 'file_name',
		},
		{
			header: 'File Type',
			field: 'file_type',
		},
		{
			header: 'File',
			field: 'link',
			body: (row: { link: string }) => {
				return row.link ? <a href={row.link} target="_blank" rel="noopener noreferrer">Link</a> : ''
			},
		},
		{
			header: 'Uploaded By',
			field: 'uploaded_by',
		},
	]

	return (
		<>
			<Table
				columns={cols}
				data={data}
				isLoading={isLoading}
				title="App Files"
			/>
		</>
	)
}
